import { createActions } from 'redux-actions'
import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const { fetchObservationsStart, fetchObservationsSuccess, fetchObservationsError } = createActions({
  FETCH_OBSERVATIONS_START: () => {},
  FETCH_OBSERVATIONS_SUCCESS: data => ({ data }),
  FETCH_OBSERVATIONS_ERROR: error => ({ error })
})

const fetchObservations = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchObservationsStart())
    try {
      const response = await api.get(
        `${API_ROUTES.PRODUCERS_BY_PRODUCER_ID}/${id}/${API_ROUTES.PRODUCERS_OBS}`
      )
      dispatch(fetchObservationsSuccess({ values: response.data }))
    } catch (error) {
      dispatch(fetchObservationsError(error))
    }
  }
}

export {
  fetchObservations,
  fetchObservationsStart,
  fetchObservationsSuccess,
  fetchObservationsError
}
