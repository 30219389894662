import { handleActions } from 'redux-actions'
import {
  fetchProducersByIdStart,
  fetchProducersByIdSuccess,
  fetchProducersByIdError,
  producersByIdReset
} from 'store/actions/producersByIds'

const defaultState = {
  values: null,
  isFetching: false,
  error: null
}

const reducer = handleActions(
  {
    [fetchProducersByIdStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchProducersByIdSuccess]: (state, { payload: { data } }) => ({
      ...state,
      isFetching: false,
      values: data
    }),
    [fetchProducersByIdError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [producersByIdReset]: () => defaultState
  },
  defaultState
)

export default reducer
