import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'

import theme from 'config/theme'

import Modal from 'components/Modal'
import ButtonMaterial from 'components/ButtonMaterial'
import TextFieldMaterial from 'components/TextFieldMaterial'

import { setEditViewStatus } from 'store/actions/editView'
import {
  modifyProducersCoordinates,
  resetProducerCoordenatesModify
} from 'store/actions/modifyProducerCoordinates'
import { setMapStatus } from 'store/actions/mapStatus'

import CrossIcon from 'assets/icons/cross.png'

import { capitalizeFirstLetter, allToLower } from 'utils/strings'

import i18n from 'services/i18n'

import {
  CrossImage,
  StylizedContainer,
  StyledDivHeaderWrapper,
  StyledDivFooterWrapper,
  StyledForm,
  StyledTitle,
  StyledSubTitle,
  ButtonWrapper,
  ErrorLabel
} from './styled'

class ItemEdit extends Component {
  onClose = () => {
    this.props.setEditViewStatus({ open: false })
  }

  componentDidMount = () => {
    this.props.resetProducerCoordenatesModify()
  }

  renderForm = () => {
    const SignUpSchema = Yup.object().shape({
      lat: Yup.string(capitalizeFirstLetter(`${i18n('EDIT_WINDOW').EDIT_WINDOW_ERROR_NUMBER}`))
        .max(11, capitalizeFirstLetter(`${i18n('EDIT_WINDOW').EDIT_WINDOW_ERROR_MAX}`))
        .required(capitalizeFirstLetter(`${i18n('EDIT_WINDOW').EDIT_WINDOW_ERROR_REQUIRED_LAT}`)),
      lng: Yup.string(capitalizeFirstLetter(`${i18n('EDIT_WINDOW').EDIT_WINDOW_ERROR_NUMBER}`))
        .max(11, capitalizeFirstLetter(`${i18n('EDIT_WINDOW').EDIT_WINDOW_ERROR_MAX}`))
        .required(capitalizeFirstLetter(`${i18n('EDIT_WINDOW').EDIT_WINDOW_ERROR_REQUIRED_LNG}`))
    })
    return (
      <Formik
        initialValues={{
          lat: this.props.mapStatus.selectedPlace.coordinates.lat.substring(0, 11),
          lng: this.props.mapStatus.selectedPlace.coordinates.lng.substring(0, 11)
        }}
        validateOnChange={false}
        validationSchema={SignUpSchema}
        onSubmit={this.handleSubmit}
        render={e => this.getFormContent(e)}
      />
    )
  }

  isSameCoord = values =>
    values.lat === this.props.mapStatus.selectedPlace.coordinates.lat.substring(0, 11) &&
    values.lng === this.props.mapStatus.selectedPlace.coordinates.lng.substring(0, 11)

  handleSubmit = async ({ lat, lng }) => {
    await this.props.modifyProducersCoordinates(
      this.props.mapStatus.selectedPlace.idUnidadProductora,
      lat,
      lng
    )
    if (this.props.modifyProducersCoordinatesStatus.values) {
      this.props.setMapStatus({
        showingInfoWindow: false
      })
      this.onClose()
    }
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <StyledForm onSubmit={handleSubmit}>
      <StyledDivHeaderWrapper>
        <CrossImage onClick={() => this.onClose()} src={CrossIcon} />
      </StyledDivHeaderWrapper>
      <StyledTitle>{this.props.mapStatus.selectedPlace.name}</StyledTitle>
      <StyledSubTitle>
        {capitalizeFirstLetter(allToLower(this.props.producersByProducerId.values.EmpresaLactea))}
      </StyledSubTitle>
      <div>
        <TextFieldMaterial
          label={capitalizeFirstLetter(`${i18n('INFO_WINDOW').WINDOW_EDIT_LAT}`)}
          helperText={errors.lat}
          margin="normal"
          value={values.lat}
          error={errors.lat}
          onChange={({ target: value }) => setFieldValue('lat', value.value)}
          type="number"
        />

        <TextFieldMaterial
          label={capitalizeFirstLetter(`${i18n('INFO_WINDOW').WINDOW_EDIT_LNG}`)}
          helperText={errors.lng}
          margin="normal"
          value={values.lng}
          error={errors.lng}
          onChange={({ target: value }) => setFieldValue('lng', value.value)}
          type="number"
        />
      </div>
      <StyledDivFooterWrapper>
        <ButtonWrapper margin={30}>
          <ButtonMaterial
            type={'submit'}
            background={
              this.isSameCoord(values) ? theme.colors.dustyGray : theme.colors.mastelloneGreen
            }
            color={theme.colors.white}
            borderRadius={'10'}
            fontSize={'18'}
            height={'45'}
            disabled={this.isSameCoord(values) ? true : false}
            loading={this.props.modifyProducersCoordinatesStatus.isFetching}
          >
            {capitalizeFirstLetter(`${i18n('INFO_WINDOW').WINDOW_SAVE_BUTTON}`)}
          </ButtonMaterial>
        </ButtonWrapper>
        <div style={{ display: 'flex' }}>
          {this.props.modifyProducersCoordinatesStatus.error && (
            <ErrorLabel>
              {capitalizeFirstLetter(`${i18n('INFO_WINDOW').WINDOW_EDIT_ERROR}`)}
            </ErrorLabel>
          )}
        </div>
      </StyledDivFooterWrapper>
    </StyledForm>
  )

  render() {
    return (
      <Modal>
        <StylizedContainer>{this.renderForm()}</StylizedContainer>
      </Modal>
    )
  }
}

const mapStateToProps = ({
  editView,
  mapStatus,
  producersByProducerId,
  modifyProducersCoordinatesStatus
}) => ({
  editViewStatus: editView,
  mapStatus,
  producersByProducerId,
  modifyProducersCoordinatesStatus
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setEditViewStatus,
      modifyProducersCoordinates,
      setMapStatus,
      resetProducerCoordenatesModify
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemEdit)
