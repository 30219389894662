import { createActions } from 'redux-actions'
import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const {
  modifyProducersCoordinatesStart,
  modifyProducersCoordinatesSuccess,
  modifyProducersCoordinatesError,
  resetProducerCoorModify
} = createActions({
  MODIFY_PRODUCERS_COORDINATES_START: () => {},
  MODIFY_PRODUCERS_COORDINATES_SUCCESS: data => ({ data }),
  MODIFY_PRODUCERS_COORDINATES_ERROR: error => ({ error }),
  RESET_PRODUCER_COOR_MODIFY: () => {}
})

const modifyProducersCoordinates = (id, lat, lng) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(modifyProducersCoordinatesStart())
    const newCoordinates = {
      latitud: lat,
      longitud: lng
    }
    try {
      const response = await api.put(`${API_ROUTES.PRODUCERS_BY_PRODUCER_ID}/${id}`, newCoordinates)
      dispatch(modifyProducersCoordinatesSuccess({ values: response.data }))
    } catch (error) {
      dispatch(modifyProducersCoordinatesError(error))
    }
  }
}

const resetProducerCoordenatesModify = () => dispatch => dispatch(resetProducerCoorModify())

export {
  modifyProducersCoordinates,
  modifyProducersCoordinatesStart,
  modifyProducersCoordinatesSuccess,
  modifyProducersCoordinatesError,
  resetProducerCoordenatesModify,
  resetProducerCoorModify
}
