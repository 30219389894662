import { createSelector } from 'reselect'
import filterKeys from 'constants/filterKeys'

export const mapPoints = createSelector(
  data => data,
  ({ points: { basePoints, filteredKeyActive }, multipleFilter }) => {
    const isMultiple = Object.keys(multipleFilter.recordedQuery).length
      ? true
      : isMultiFilter(multipleFilter)

    const minimumFiltersActiveAmount = multiFilterKeyAmount(multipleFilter) - 1

    return basePoints.filter(p => {
      const filterBusiness = isFilterByBusiness(p, filteredKeyActive, multipleFilter)
      const filterProvince = isFilterByProvince(p, filteredKeyActive, multipleFilter)
      const filterZone = isFilterByZone(p, filteredKeyActive, multipleFilter)

      return isMultiple
        ? (Object.keys(multipleFilter.recordedQuery).length
            ? filterBusiness + filterProvince + filterZone > minimumFiltersActiveAmount
            : filterBusiness + filterProvince + filterZone >= minimumFiltersActiveAmount) || false
        : true
    })
  }
)

const isMultiFilter = multipleFilter => multiFilterKeyAmount(multipleFilter) >= 2

const multiFilterKeyAmount = multipleFilter => {
  const businessFilter = Object.keys(multipleFilter[filterKeys.BUSINESS]).length >= 1
  const provinceFilter = Object.keys(multipleFilter[filterKeys.PROVINCE]).length >= 1
  const zoneFilters = Object.keys(multipleFilter[filterKeys.ZONE]).length >= 1
  return zoneFilters + provinceFilter + businessFilter
}

const isFilterByBusiness = (producer, activeKey, filterReducer) => {
  return (
    filterKeys.BUSINESS !== activeKey &&
    checkZones(producer.ListaIdEmpresa, filterReducer[filterKeys.BUSINESS])
  )
}

const checkZones = (producerZones, filterZones) => {
  return producerZones.find(zoneId => Object.keys(filterZones).includes(`${zoneId}`)) !== undefined
}

const isFilterByProvince = (producer, activeKey, filterReducer) =>
  isFilterBy(producer, activeKey, filterReducer, filterKeys.PROVINCE, 'IdProvincia')

const isFilterByZone = (producer, activeKey, filterReducer) =>
  isFilterBy(producer, activeKey, filterReducer, filterKeys.ZONE, 'IdZona')

const isFilterBy = (producer, activeKey, filterReducer, resource, target) =>
  resource !== activeKey && Object.keys(filterReducer[resource]).includes(`${producer[target]}`)

export const mapProducersInfo = createSelector(
  data => data,
  ({ producersInfo, pointsMapped, multipleFilter }) => {
    if (isMultiFilter(multipleFilter)) {
      return updateProducersInfo(producersInfo, pointsMapped)
    } else {
      return producersInfo
    }
  }
)

const updateProducersInfo = (producersInfo, points) => {
  const updatedInfo = JSON.parse(JSON.stringify(producersInfo))
  let amounts = {
    tamboMastelloneAmount: 0,
    tamboMastelloneCapacity: 0,
    tamboCompetenceAmount: 0,
    tamboCompetenceCapacity: 0
  }

  points.reduce((accum, cur) => {
    if (cur.EsMHSA && cur.EsTambo) {
      accum.tamboMastelloneAmount += 1
      accum.tamboMastelloneCapacity += cur.Litros
    } else if (!cur.EsMHSA && cur.EsTambo) {
      accum.tamboCompetenceAmount += 1
      accum.tamboCompetenceCapacity += cur.Litros
    }
    return accum
  }, amounts)

  if (updatedInfo) {
    updatedInfo.tambosMhsa.cantidad = amounts.tamboMastelloneAmount
    updatedInfo.tambosMhsa.capacidad = amounts.tamboMastelloneCapacity / 1000
    updatedInfo.tambosCompetencia.cantidad = amounts.tamboCompetenceAmount
    updatedInfo.tambosCompetencia.capacidad = amounts.tamboCompetenceCapacity / 1000
  }

  return updatedInfo
}
