import { handleActions } from 'redux-actions'
import { selectedProvinceToDelete, resetProvinceToDelete } from 'store/actions/provinceToDelete'

const defaultState = {
  id: null
}

const reducer = handleActions(
  {
    [selectedProvinceToDelete]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => status,
    [resetProvinceToDelete]: () => defaultState
  },
  defaultState
)

export default reducer
