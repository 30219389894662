import React, { Component } from 'react'
import { Dialog, withWidth, withStyles } from '@material-ui/core'
import Loader from 'react-spinners/BounceLoader'
import { compose } from 'recompose'
import { breakpoints } from 'config/media-queries'

class LoaderDialog extends Component {
  state = {}

  render() {
    const { isSideBarOpen } = this.props
    var innerWidth = window.innerWidth < breakpoints.mobile
    return (
      <Dialog
        PaperProps={{
          style: {
            background: 'transparent',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: 'none',
            marginLeft: innerWidth ? '' : isSideBarOpen ? '300px' : ''
          }
        }}
        open
      >
        <Loader />
      </Dialog>
    )
  }
}

export default compose(
  withWidth(),
  withStyles({ withTheme: true })
)(LoaderDialog)
