import styled from 'styled-components'
import { Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 0 12px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;

  & > li {
    padding: 0 !important;
  }
`

export const Title = styled.label`
  font-size: 20px;
  padding: 10px 0 10px 11px;
`

export const NoData = styled.label`
  font-size: 16px;
  padding: 10px 0 10px 11px;
`

export const StyledButton = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  outline: none;
  cursor: pointer;
  margin: 10px 0px;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
`

export const StyledDivSpacer = styled.div`
  height: 20px;
`

export const ColorCircle = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`

const checkBoxStyles = {
  root: {
    '&$checked': {
      color: '#19975d'
    }
  },
  checked: {}
}

export const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox)
