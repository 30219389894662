import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isUserLoggedIn } from 'utils/session'
import { getUrlIds } from 'utils/url'
import { saveCachedIds } from 'utils/session'
import i18n from 'services/i18n'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const isUserLogged = isUserLoggedIn()
      const ids = getUrlIds()
      if (ids && !isUserLogged) {
        saveCachedIds(ids)
      }
      return isUserLogged ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: `/${i18n('ROUTES').ROUTE_LOGIN}`,
            state: { from: props.location }
          }}
        />
      )
    }}
  />
)

export default PrivateRoute
