import { combineReducers } from 'redux'

import authReducer from './store/reducers/authentication.reducer'
import editView from './store/reducers/editView.reducer'
import mapStatus from './store/reducers/mapStatus.reducer'
import selectedProvince from './store/reducers/selectedProvince.reducer'
import selectedProvinceToDelete from './store/reducers/provinceToDelete.reducer'
import business from './store/reducers/business.reducer'
import producersByBusinessId from './store/reducers/producersByBusinessId.reducer'
import provinces from './store/reducers/provinces.reducer'
import drying from './store/reducers/drying.reducer'
import producersByProvinceId from './store/reducers/producersByProvinceId.reducer'
import filter from './store/reducers/filter.reducer'
import zones from './store/reducers/zones.reducer'
import producersByZoneId from './store/reducers/producersByZoneId.reducer'
import multipleFilter from './store/reducers/multipleFilter.reducer'
import producersByProducerId from './store/reducers/producersByProducerId.reducer'
import modifyProducersCoordinatesStatus from './store/reducers/modifyProducerCoordinates.reducer'
import points from 'store/reducers/points'
import producersInfo from 'store/reducers/producersInfo'
import producersByArea from 'store/reducers/producersByArea'
import producersByIds from 'store/reducers/producersByIds'
import observations from 'store/reducers/observations'
import businessAdditional from 'store/reducers/businessAdditional.reducer'
import businessGrid from 'store/reducers/businessGrid.reducer'
import latestFilterApplied from 'store/reducers/latestFilterApplied'
import editMode from 'store/reducers/editMode'
import producersByLevel from 'store/reducers/producersByLevel'
import ui from 'store/reducers/ui'
import recordedQueries from 'store/reducers/recordedQueries.reducer'

const todoApp = combineReducers({
  auth: authReducer,
  editView,
  mapStatus,
  selectedProvince,
  selectedProvinceToDelete,
  business,
  producersByBusinessId,
  provinces,
  drying,
  producersByProvinceId,
  filter,
  zones,
  producersByZoneId,
  multipleFilter,
  producersByProducerId,
  modifyProducersCoordinatesStatus,
  businessAdditional,
  businessGrid,
  points,
  producersInfo,
  producersByArea,
  producersByIds,
  observations,
  latestFilterApplied,
  editMode,
  producersByLevel,
  ui,
  recordedQueries
})

export default todoApp
