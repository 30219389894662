import { handleActions } from 'redux-actions'
import { setFilter, resetFilter, resetRecordedQueries } from 'store/actions/multipleFilter'

const defaultState = {
  business: {},
  province: {},
  zone: {},
  recordedQuery: {},
  drying: {}
}

const reducer = handleActions(
  {
    [setFilter]: (state, { payload: { key, filter } }) => ({
      ...state,
      [key]: filter
    }),
    [resetFilter]: () => defaultState,
    [resetRecordedQueries]: state => ({
      ...state,
      recordedQuery: {}
    })
  },
  defaultState
)

export default reducer
