import React from 'react'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross.png'
import { SuccessButton, CancelButton } from 'components/Button'
import { Container, Wrapper, CrossImage, Title, ButtonsContainer, ButtonWrapper } from './styled'
import i18n from 'services/i18n'
import { capitalizeFirstLetter } from 'utils/strings'

const PopUp = ({ onClose, title, onSuccess, onCancel }) => (
  <Modal>
    <Container>
      <Wrapper>
        <CrossImage onClick={onClose} src={CrossIcon} />
        <Title>{title}</Title>
        <ButtonsContainer>
          <SuccessButton type={'button'} onClick={onSuccess} loading={false}>
            {capitalizeFirstLetter(`${i18n('POPUP').ACCEPT}`)}
          </SuccessButton>
          <ButtonWrapper>
            <CancelButton type={'button'} onClick={onCancel}>
              {capitalizeFirstLetter(`${i18n('POPUP').CANCEL}`)}
            </CancelButton>
          </ButtonWrapper>
        </ButtonsContainer>
      </Wrapper>
    </Container>
  </Modal>
)

export default PopUp
