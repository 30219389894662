import { handleActions } from 'redux-actions'
import { setInfo, resetInfo } from 'store/actions/producersInfo'

const defaultState = null

const reducer = handleActions(
  {
    [setInfo]: (state, { payload: { data } }) => ({
      ...data
    }),
    [resetInfo]: () => defaultState
  },
  defaultState
)

export default reducer
