import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    .Toastify__toast--default {
      box-shadow: none !important;
      background: transparent !important;
    }
  `

export default GlobalStyle
