import { createActions } from 'redux-actions'
import filterKeys from 'constants/filterKeys'

const { setFilter, resetFilter, resetRecordedQueries } = createActions({
  SET_FILTER: (key, filter) => ({ key, filter }),
  RESET_FILTER: () => {},
  RESET_RECORDED_QUERIES: () => {}
})

const resetSelectedRecordedQueries = () => dispatch => dispatch(resetRecordedQueries())

const setSelectedBusiness = item => addFilter(filterKeys.BUSINESS, item)

const setSelectedProvince = item => addFilter(filterKeys.PROVINCE, item)

const setSelectedDrying = item => addFilter(filterKeys.DRYING, item)

const setSelectedZone = item => addFilter(filterKeys.ZONE, item)

const setSelectedRecordedQuery = item => addFilter(filterKeys.RECORDED_QUERYS, item)

const addFilter = (filterKey, item) => (dispatch, getState) => {
  const { multipleFilter } = getState()
  let updatedFilter = { ...multipleFilter[filterKey] }
  updatedFilter[item.IdItem] = item
  dispatch(setFilter(filterKey, updatedFilter))
}

const deleteSelectedBusiness = item => deleteFilter(filterKeys.BUSINESS, item)

const deleteSelectedProvince = item => deleteFilter(filterKeys.PROVINCE, item)

const deleteSelectedDrying = item => deleteFilter(filterKeys.DRYING, item)

const deleteSelectedZone = item => deleteFilter(filterKeys.ZONE, item)

const deleteSelectedRecordedQuery = item => deleteFilter(filterKeys.RECORDED_QUERYS, item)

const deleteFilter = (filterKey, item) => (dispatch, getState) => {
  const { multipleFilter } = getState()
  let updatedFilter = { ...multipleFilter[filterKey] }
  delete updatedFilter[item.IdItem]
  dispatch(setFilter(filterKey, updatedFilter))
}

const reset = () => dispatch => dispatch(resetFilter())

export {
  setFilter,
  setSelectedBusiness,
  setSelectedProvince,
  setSelectedDrying,
  setSelectedZone,
  setSelectedRecordedQuery,
  deleteSelectedBusiness,
  deleteSelectedProvince,
  deleteSelectedDrying,
  deleteSelectedZone,
  deleteSelectedRecordedQuery,
  reset,
  resetFilter,
  resetSelectedRecordedQueries,
  resetRecordedQueries
}
