import { createActions } from 'redux-actions'
import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const {
  fetchBusinessAdditionalStart,
  fetchBusinessAdditionalSuccess,
  fetchBusinessAdditionalError
} = createActions({
  FETCH_BUSINESS_ADDITIONAL_START: () => {},
  FETCH_BUSINESS_ADDITIONAL_SUCCESS: data => ({ data }),
  FETCH_BUSINESS_ADDITIONAL_ERROR: error => ({ error })
})

const fetchBusinessAdditional = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchBusinessAdditionalStart())
    try {
      const response = await api.get(`${API_ROUTES.PRODUCERS_BUSINESS_BY_PRODUCER_ID}/${id}`)
      dispatch(fetchBusinessAdditionalSuccess({ values: response.data }))
    } catch (error) {
      dispatch(fetchBusinessAdditionalError(error))
    }
  }
}

export {
  fetchBusinessAdditional,
  fetchBusinessAdditionalStart,
  fetchBusinessAdditionalSuccess,
  fetchBusinessAdditionalError
}
