import { handleActions } from 'redux-actions'
import { mapStatus } from 'store/actions/mapStatus'
import { basePosition } from 'config/map'

const defaultState = {
  showingInfoWindow: false,
  activeMarker: {},
  selectedPlace: {},
  mapZoom: 5,
  selectedLocation: null,
  center: basePosition
}

const reducer = handleActions(
  {
    [mapStatus]: (state, { payload: { data } }) => {
      return data
    }
  },
  defaultState
)

export default reducer
