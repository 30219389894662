import { handleActions } from 'redux-actions'
import {
  modifyProducersCoordinatesStart,
  modifyProducersCoordinatesSuccess,
  modifyProducersCoordinatesError,
  resetProducerCoorModify
} from 'store/actions/modifyProducerCoordinates'

const defaultState = {
  values: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [modifyProducersCoordinatesStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [modifyProducersCoordinatesSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [modifyProducersCoordinatesError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [resetProducerCoorModify]: () => defaultState
  },
  defaultState
)

export default reducer
