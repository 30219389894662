import { createActions } from 'redux-actions'
import filterKeys from 'constants/filterKeys'

const { setRawPoints, resetPointsReducer } = createActions({
  SET_RAW_POINTS: points => points,
  RESET_POINTS_REDUCER: () => {}
})

const setBusinessPoints = points => setPoints(points, filterKeys.BUSINESS)

const setProvincePoints = points => setPoints(points, filterKeys.PROVINCE)

const setZonePoints = points => setPoints(points, filterKeys.ZONE)

const setDryingPoints = points => setPoints(points, filterKeys.DRYING)

const setRecordedPoints = points => setPoints(points, filterKeys.RECORDED_QUERYS)

const setPoints = (points, key) => dispath => dispath(setRawPoints({ points, key }))

const resetPoints = () => dispath => dispath(resetPointsReducer())

export {
  setBusinessPoints,
  setProvincePoints,
  setZonePoints,
  setDryingPoints,
  setRecordedPoints,
  setRawPoints,
  resetPointsReducer,
  resetPoints
}
