import { handleActions } from 'redux-actions'
import { drawerOpen, drawerClose, activeRadiusSet, activeRadiusRemove } from 'store/actions/ui'

const defaultState = {
  isDrawerOpen: true,
  activeRadius: 0
}

const reducer = handleActions(
  {
    [drawerOpen]: state => ({ ...state, isDrawerOpen: true }),
    [drawerClose]: state => ({ ...state, isDrawerOpen: false }),
    [activeRadiusSet]: (state, { payload: { radius } }) => ({ ...state, activeRadius: radius }),
    [activeRadiusRemove]: state => ({ ...state, activeRadius: 0 })
  },
  defaultState
)

export default reducer
