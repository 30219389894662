import { createActions } from 'redux-actions'
import filterKeys from 'constants/filterKeys'

const { activeFilters, resetActiveFilters } = createActions({
  ACTIVE_FILTERS: formattedData => ({ formattedData }),
  RESET_ACTIVE_FILTERS: () => {}
})

const setActiveFilters = data => dispatch => {
  const formattedData = {}
  formattedData[filterKeys.BUSINESS] = data.empresas
  formattedData[filterKeys.PROVINCE] = data.provincias
  formattedData[filterKeys.ZONE] = data.zonas
  formattedData[filterKeys.DRYING] = []
  dispatch(activeFilters(formattedData))
}

const setActiveFiltersFromMap = data => dispatch => dispatch(activeFilters(data))

const resetFilters = () => dispatch => dispatch(resetActiveFilters())

export {
  activeFilters,
  setActiveFilters,
  setActiveFiltersFromMap,
  resetFilters,
  resetActiveFilters
}
