import axios from 'axios'
import { getUrlIds } from 'utils/url'
import { saveCachedIds, removeLocalStatus } from 'utils/session'
import API_ROUTES from 'config/apiRoutes'

export default () => {
  const accessToken = localStorage.getItem('@mastelloneMap/token')

  let headerConfLogin = {
    'Content-Type': 'application/json'
  }

  let headerConf = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  }

  let api = axios.create({
    baseURL: API_ROUTES.BASE_URL,
    headers: {
      common: accessToken ? headerConf : headerConfLogin
    }
  })

  api.interceptors.response.use(
    response => response,
    async error => {
      if (!error.response.request.responseURL.includes('login') && error.response.status === 401) {
        removeLocalStatus()
        const ids = getUrlIds()
        if (ids && error.request.responseURL.includes(API_ROUTES.PRODUCERS_BY_IDS)) {
          saveCachedIds(ids)
        }
        window.location.reload()
      }
    }
  )

  return api
}
