export default {
  APP_NAME: 'mercado lácteo',
  ROUTES: {
    ROUTE_LOGIN: 'login',
    ROUTE_MAP: 'mapa'
  },
  LOGIN: {
    LOGIN_FORM_INPUT_USERNAME: 'usuario',
    LOGIN_FORM_INPUT_PASSWORD: 'contraseña',
    LOGIN_FORM_BUTTON: 'ingresar',
    LOGIN_FORM_USERNAME_ERROR: 'usuario requerido',
    LOGIN_FORM_PASSWORD_ERROR: 'contraseña requerida',
    LOGIN_ERROR_MESSAGE: 'no fue posible ingresar'
  },
  CUSTOM_DRAWER: {
    DRAWER_GREETINGS: 'hola!',
    DRAWER_BUSINESS: 'empresas',
    DRAWER_PROVINCES: 'provincias',
    DRAWER_ZONES: 'zonas',
    DRAWER_SEE_MORE: 'ver más',
    DRAWER_SEE_LESS: 'ver menos',
    DRAWER_NO_DATA_TO_SHOW: 'no hay datos para mostrar',
    DRAWER_RECORDED_QUERIES: 'consultas guardadas'
  },
  INFO_WINDOW: {
    WINDOW_EDIT_BUTTON: 'editar',
    WINDOW_MOVE_BUTTON: 'mover',
    WINDOW_SAVE_BUTTON: 'guardar',
    WINDOW_EDIT_LOCALIZATION: 'localización',
    WINDOW_EDIT_LAT: 'latitud',
    WINDOW_EDIT_LNG: 'longitud',
    WINDOW_EDIT_KM: 'kilómeters',
    WINDOW_EDIT_LITERS: 'litros',
    WINDOW_EDIT_PRICE: 'precio',
    WINDOW_EDIT_ERROR: 'hubo un problema',
    WINDOW_EDIT_SELECTED_POS: 'posición seleccionada',
    WINDOW_EDIT_NO_OBS: 'no hay observaciones',
    WINDOW_EDIT_OBS: 'observaciones',
    REFERENCE_DATE: 'fecha de referencia'
  },
  EDIT_WINDOW: {
    EDIT_WINDOW_ERROR_NUMBER: 'use un número válido',
    EDIT_WINDOW_ERROR_REQUIRED_LAT: 'por favor inserte una latitud válida',
    EDIT_WINDOW_ERROR_REQUIRED_LNG: 'por favor inserte una longitud válida',
    EDIT_WINDOW_ERROR_MAX: 'la coordenada es muy larga'
  },
  CIRCLE_WINDOW: {
    CIRCLE_WINDOW_RADIUS: 'radio',
    CIRCLE_WINDOW_CENTER: 'centro'
  },
  TABLE: {
    TABLE_PU: 'up',
    TABLE_CUANTITY: 'cant.',
    TABLE_CAPACITY: 'cant. lts.',
    TABLE_1000: '(x 1000)',
    TABLE_PRICE: 'precio promedio ($)',
    TABLE_TAMBO: 'tambos',
    TABLE_INDUSTRY: 'industria',
    TABLE_MH: 'mh',
    TABLE_COMPETENCE: 'competencia',
    LITERS_AVERAGE: 'Cant. lts. tambos',
    TABLE_MASTELLONE: 'mhsa',
    TABLE_OTHERS: 'resto'
  },
  EDIT_MODE_ENABLED: 'Modo edición activado',
  POPUP: {
    MAIN_FILTER_HINT: ' Al quitar este filtro se limpiará la selección completa. Está seguro?',
    ACCEPT: 'aceptar',
    CANCEL: 'cancelar',
    RELOCATE: 'está seguro que desea reubicar a'
  },
  ALERT_POPUP: {
    SORRY: 'lo sentimos!',
    NO_POINTS: 'No hay puntos dentro del área seleccionada',
    NO_BUSINESS_POINTS: 'No hay puntos asociados a las empresas seleccionadas'
  },
  TAMBO_HINT: {
    UNTIL: 'hasta 5.000lts.',
    BETWEEN: 'entre 5.000lts. y 10.000lts.',
    HIGHER: 'mayor a 10.000lts.'
  },
  LEVEL_SELECTION: {
    LEVEL: 'nivel',
    ERROR: 'error al conseguir los puntos'
  },
  404: {
    ROUTE_NOT_FOUND: 'no hemos encontrado la ruta, ',
    REDIRECT_MESSAGE: 'redirigir a la página principal'
  }
}
