import { createActions } from 'redux-actions'

const { enableEditMode, disableEditMode } = createActions({
  ENABLE_EDIT_MODE: () => {},
  DISABLE_EDIT_MODE: () => {}
})

const editModeEnabled = () => dispatch => dispatch(enableEditMode())

const editModeDisabled = () => dispatch => dispatch(disableEditMode())

export { enableEditMode, editModeEnabled, disableEditMode, editModeDisabled }
