import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import i18n from 'services/i18n'
import { capitalizeFirstLetter } from 'utils/strings'
import { reestructureProvinceData } from 'utils/provinces'
import { setSelectedProvinces } from 'store/actions/selectedProvince'
import { setSelectedProvinceToDelete } from 'store/actions/provinceToDelete'
import mapMultipleFilter, { filterBusinessByAreaPoints } from 'store/selectors/mapMultipleFilters'
import mapCheckResources, { mapCheckBusiness } from 'store/selectors/mapCheckResources'
import SimpleCheckList from 'components/SimpleCheckList'
import { fetchBusiness } from 'store/actions/business'
import { fetchBusinessGrid } from 'store/actions/businessGrid'
import { fetchProvinces } from 'store/actions/provinces'
import { fetchDrying, deleteDryingFilterById } from 'store/actions/drying'
import { fetchZones } from 'store/actions/zones'
import filterKeys from 'constants/filterKeys'
import { Divider } from '@material-ui/core'
import Logo from 'assets/imgs/logo.png'
import ChipList from 'components/ChipList'
import ChartModal from '../ChartModal'
import {
  fetchProducersByProvinceId,
  deleteProducersByProvinceId
} from 'store/actions/producersByProvinceId'
import { resetAreaPoints } from 'store/actions/producersByArea'
import {
  fetchProducersByBusinessId,
  deleteProducersByBusinessId
} from 'store/actions/producersByBusinessId'
import { fetchProducersByZoneId, deleteProducersByZoneId } from 'store/actions/producersByZoneId'
import { resetFilters } from 'store/actions/filter'
import { resetProducersInfo } from 'store/actions/producersInfo'
import { resetProducersByIds } from 'store/actions/producersByIds'
import { setMapStatus } from 'store/actions/mapStatus'
import {
  fetchRecordedQueries,
  fetchRecordedQueriesById,
  resetSelectdRecordedQueriesById
} from 'store/actions/recordedQueries'
import { fetchProducersByLevel, restartProducersByLevel } from 'store/actions/producersByLevel'

import { basePosition } from 'config/map'
import {
  setBusinessPoints,
  setProvincePoints,
  setZonePoints,
  setDryingPoints,
  setRecordedPoints,
  resetPoints
} from 'store/actions/points'
import { setLatestFilterApplied, resetLatestFilterApplied } from 'store/actions/latestFilterApplied'
import PopUp from 'components/PopUp'
import {
  setSelectedBusiness,
  setSelectedProvince,
  setSelectedZone,
  setSelectedDrying,
  setSelectedRecordedQuery,
  deleteSelectedBusiness,
  deleteSelectedProvince,
  deleteSelectedZone,
  deleteSelectedDrying,
  deleteSelectedRecordedQuery,
  reset,
  resetSelectedRecordedQueries
} from 'store/actions/multipleFilter'
import LevelSection from 'components/LevelSection'
import { ScrollWrapper, Container, LogoWrapper, StyledImg, ModalButtonTitle } from './styled'

class SideBar extends React.Component {
  state = { confirmationPopUp: false, show: false }

  componentDidMount = () => {
    if (!this.props.business.values || this.props.business.values.length === 0)
      this.props.fetchBusiness()
    if (!this.props.producersByLevel.baseValues.lvl1.length) this.props.fetchProducersByLevel(1)
    if (!this.props.producersByLevel.baseValues.lvl2.length) this.props.fetchProducersByLevel(2)
    if (!this.props.producersByLevel.baseValues.lvl3.length) this.props.fetchProducersByLevel(3)
    if (!this.props.provinces.values) this.props.fetchProvinces()
    if (!this.props.zones.values) this.props.fetchZones()
    if (!this.props.recordedQueries.values) this.props.fetchRecordedQueries()
    if (!this.props.drying.values) this.props.fetchBusinessGrid()
  }

  componentDidUpdate = prevProps => {
    if (prevProps.recordedQueries.valuesById && !this.props.recordedQueries.valuesById) {
      this.props.setMapStatus({
        showingInfoWindow: false,
        selectedPlace: {}
      })
    }
  }

  renderCheckList = (title, values, isLoading, onItemSelected, selected) => (
    <SimpleCheckList
      title={title}
      values={values}
      isLoading={isLoading}
      onItemSelected={item => {
        this.resetData()
        onItemSelected(item)
      }}
      selected={selected}
      disabled={this.props.producersByArea.values}
    />
  )

  closeModal = () => {
    this.setState({
      show: false
    })
  }

  showModal = () => {
    this.setState({
      show: true
    })
  }

  resetData = () => {
    this.props.resetAreaPoints()
    this.props.resetProducersByIds()
  }

  getResourceLength = resource => Object.keys(resource).length

  handleBusinessSelection = async business => {
    !this.props.multipleFilter.business[business.IdItem]
      ? this.addBusiness(business)
      : this.handleDeleteBusiness(business)
  }

  arePointsAvaiable = () => this.props.basePoints.length > 0

  addBusiness = business => {
    this.props.setSelectedBusiness(business)
    if (this.props.filteredKeyActive && !this.props.latestFilterApplied) {
      this.props.setLatestFilterApplied(filterKeys.BUSINESS)
    }
    if (this.handleFetchContent(filterKeys.BUSINESS)) {
      this.fetchBusinessPoints(business.IdItem)
    }
  }

  fetchBusinessPoints = async businessId => {
    await this.props.fetchProducersByBusinessId(businessId)
    setTimeout(() => this.props.setBusinessPoints(this.props.producersByBusinessId.values), 10)
  }

  handleDeleteBusiness = business => {
    if (
      this.props.filteredKeyActive === filterKeys.BUSINESS &&
      this.getResourceLength(this.props.multipleFilter.business) === 1
    ) {
      this.openConfirmationPopUp({ resource: business, action: this.deleteBusiness })
    } else {
      this.deleteBusiness(business)
    }
  }

  deleteBusiness = business => {
    this.props.deleteSelectedBusiness(business)
    this.props.deleteProducersByBusinessId(business)
    if (
      this.props.filteredKeyActive === filterKeys.BUSINESS &&
      this.getResourceLength(this.props.multipleFilter.business) === 1
    ) {
      this.resetAllFilters()
    } else if (this.props.filteredKeyActive === filterKeys.BUSINESS) {
      this.fetchBusinessPoints(0)
    }
  }

  handleProvinceSelection = async province =>
    !this.props.multipleFilter.province[province.IdItem]
      ? this.addProvince(province)
      : this.handleDeleteProvince(province)

  addProvince = province => {
    const reestructuredItem = reestructureProvinceData(province)
    if (this.props.filteredKeyActive && !this.props.latestFilterApplied) {
      this.props.setLatestFilterApplied(filterKeys.PROVINCE)
    }
    this.props.setMapStatus({
      mapZoom: 7,
      center: reestructuredItem.coordinates
    })
    this.props.setSelectedProvince(province)
    this.props.setSelectedProvinces(reestructuredItem)
    if (this.handleFetchContent(filterKeys.PROVINCE)) {
      this.fetchProvincePoints(reestructuredItem.id)
    }
  }

  fetchProvincePoints = async provinceId => {
    await this.props.fetchProducersByProvinceId(provinceId)
    setTimeout(this.props.setProvincePoints(this.props.producersByProvinceId.values), 10)
  }

  handleDeleteProvince = province => {
    if (
      this.props.filteredKeyActive === filterKeys.PROVINCE &&
      this.getResourceLength(this.props.multipleFilter.province) === 1
    ) {
      this.openConfirmationPopUp({ resource: province, action: this.deleteProvince })
    } else {
      this.deleteProvince(province)
    }
  }

  deleteProvince = province => {
    if (!province.IdItem) {
      province = { IdItem: province.id, Descripcion: province.name }
    }
    const reestructuredItem = reestructureProvinceData(province)
    this.props.deleteSelectedProvince(province)
    this.props.setSelectedProvinceToDelete(reestructuredItem)
    this.props.deleteProducersByProvinceId(province)
    if (
      this.props.filteredKeyActive === filterKeys.PROVINCE &&
      this.getResourceLength(this.props.multipleFilter.province) === 1
    ) {
      this.resetAllFilters()
    } else if (this.props.filteredKeyActive === filterKeys.PROVINCE) {
      this.fetchProvincePoints(0)
    }
  }

  handleZoneSelection = zone =>
    !this.props.multipleFilter.zone[zone.IdItem] ? this.addZone(zone) : this.handleDeleteZone(zone)

  addZone = zone => {
    this.props.setSelectedZone(zone)
    if (this.props.filteredKeyActive && !this.props.latestFilterApplied) {
      this.props.setLatestFilterApplied(filterKeys.ZONE)
    }
    if (this.handleFetchContent(filterKeys.ZONE)) {
      this.fetchZonePoints(zone.IdItem)
    }
  }

  fetchZonePoints = async zoneId => {
    await this.props.fetchProducersByZoneId(zoneId)
    setTimeout(() => this.props.setZonePoints(this.props.producersByZoneId.values), 10)
  }

  handleDeleteZone = zone => {
    if (
      this.props.filteredKeyActive === filterKeys.ZONE &&
      this.getResourceLength(this.props.multipleFilter.zone) === 1
    ) {
      this.openConfirmationPopUp({ resource: zone, action: this.deleteZone })
    } else {
      this.deleteZone(zone)
    }
  }

  deleteZone = zone => {
    this.props.deleteSelectedZone(zone)
    this.props.deleteProducersByZoneId(zone)
    if (
      this.props.filteredKeyActive === filterKeys.ZONE &&
      this.getResourceLength(this.props.multipleFilter.zone) === 1
    ) {
      this.resetAllFilters()
    } else if (this.props.filteredKeyActive === filterKeys.ZONE) {
      this.fetchZonePoints(0)
    }
  }

  handleDryingSelection = drying =>
    !this.props.multipleFilter.drying[drying.IdItem]
      ? this.addDrying(drying)
      : this.handleDeleteDrying(drying)

  addDrying = drying => {
    this.resetAllFilters()
    this.props.setSelectedDrying(drying)
    if (this.props.filteredKeyActive && !this.props.latestFilterApplied) {
      this.props.setLatestFilterApplied(filterKeys.DRYING)
    }
    if (this.handleFetchContent(filterKeys.DRYING)) {
      this.fetchDryingPoints()
    }
  }
  fetchDryingPoints = async () => {
    await this.props.fetchDrying()
    setTimeout(() => this.props.setDryingPoints(this.props.drying.values), 10)
  }

  handleDeleteDrying = drying => {
    if (
      this.props.filteredKeyActive === filterKeys.DRYING &&
      this.getResourceLength(this.props.multipleFilter.drying) === 1
    ) {
      this.openConfirmationPopUp({ resource: drying, action: this.deleteDrying })
    } else {
      this.deleteDrying(drying)
    }
  }

  deleteDrying = drying => {
    this.props.deleteSelectedDrying(drying)
    this.props.deleteDryingFilterById(drying)
    if (
      this.props.filteredKeyActive === filterKeys.DRYING &&
      this.getResourceLength(this.props.multipleFilter.drying) === 1
    ) {
      this.resetAllFilters()
    } else if (this.props.filteredKeyActive === filterKeys.DRYING) {
      this.props.drying.values = null
    }
  }

  handleFetchContent = key => !this.props.filteredKeyActive || this.props.filteredKeyActive === key

  checkProvinceDelete = () => {
    if (
      this.props.selectedProvince.id !== '' &&
      this.props.filteredKeyActive !== filterKeys.PROVINCE
    ) {
      this.deleteProvince(this.props.selectedProvince)
    }
  }

  handleFilterDelete = filter => {
    if (filter.filterKey === filterKeys.BUSINESS) {
      this.handleDeleteBusiness(filter)
    } else if (filter.filterKey === filterKeys.PROVINCE) {
      filter.Descripcion = filter.label
      this.handleDeleteProvince(filter)
    } else if (filter.filterKey === filterKeys.ZONE) {
      this.handleDeleteZone(filter)
    } else if (filter.filterKey === filterKeys.DRYING) {
      this.handleDeleteDrying(filter)
    }
  }

  resetAllFilters = () => {
    this.props.resetPoints()
    this.props.resetFilters()
    this.props.reset()
    this.props.resetProducersInfo()
    this.props.restartProducersByLevel()
    this.props.resetProducersByIds()
    this.checkProvinceDelete()
    this.props.setMapStatus({
      center: this.props.mapStatus.center || basePosition,
      zoom: 6,
      showingInfoWindow: false,
      activeMarker: null
    })
  }

  resetPartialFilters = () => {
    this.props.resetProducersByIds()
    this.props.resetAreaPoints()
    this.checkProvinceDelete()
  }

  openConfirmationPopUp = value => this.setState({ confirmationPopUp: value })

  closeConfirmationPopUp = () => this.setState({ confirmationPopUp: false })

  handleConfirmationAccept = () => {
    this.state.confirmationPopUp.action(this.state.confirmationPopUp.resource)
    this.closeConfirmationPopUp()
  }

  reestructureSelectedRecordedQuery = recordedQuery => {
    return { Descripcion: recordedQuery.Nombre, IdItem: recordedQuery.IdConsultaCompartida }
  }
  reestructureRecordedQueries = recordedQueries => {
    if (recordedQueries) {
      return recordedQueries.map(item => ({
        ...item,
        IdItem: item.IdConsultaCompartida
      }))
    } else {
      return []
    }
  }

  handleQuerySelection = recordedQuery => {
    !this.props.multipleFilter.recordedQuery[recordedQuery.IdItem]
      ? this.addRecordedQuery(recordedQuery)
      : this.deleteRecordedQuery(recordedQuery)
  }

  addRecordedQuery = async recordedQuery => {
    this.props.setSelectedRecordedQuery(this.reestructureSelectedRecordedQuery(recordedQuery))
    await this.props.fetchRecordedQueriesById(recordedQuery.IdConsultaCompartida)
    setTimeout(
      () => this.props.setRecordedPoints(this.props.recordedQueries.valuesById.productoras),
      10
    )
  }

  deleteRecordedQuery = recordedQuery => {
    this.props.deleteSelectedRecordedQuery(this.reestructureSelectedRecordedQuery(recordedQuery))
    this.props.resetSelectdRecordedQueriesById()
  }

  render() {
    var dryingFilter = [{ Descripcion: 'Secado', IdItem: 99 }]
    return (
      <Container visible={this.props.visible} mobileHidden={this.props.mobileHidden}>
        <LogoWrapper>
          <StyledImg src={Logo} />
        </LogoWrapper>
        <ScrollWrapper>
          <ChipList values={this.props.filterChips} onChipDelete={this.handleFilterDelete} />
          {this.props.filterChips.length > 0 && <Divider style={{ width: '100%' }} />}
          <LevelSection
            title={capitalizeFirstLetter(`${i18n('CUSTOM_DRAWER').DRAWER_BUSINESS}`)}
            data={this.props.businessByLevel.values}
            selected={this.props.multipleFilter.business}
            disabled={this.props.producersByArea.values}
            onItemSelected={item => {
              this.resetData()
              this.handleBusinessSelection(item)
            }}
            resetAll={this.resetAllFilters}
            resetPartial={this.resetPartialFilters}
          />
          <Divider style={{ width: '100%' }} />
          {this.renderCheckList(
            capitalizeFirstLetter(`${i18n('CUSTOM_DRAWER').DRAWER_PROVINCES}`),
            this.props.provinces.values,
            this.props.provinces.isFetching,
            this.handleProvinceSelection,
            this.props.multipleFilter.province
          )}
          <Divider style={{ width: '100%' }} />
          {this.renderCheckList(
            capitalizeFirstLetter(`${i18n('CUSTOM_DRAWER').DRAWER_ZONES}`),
            this.props.zones.values,
            this.props.zones.isFetching,
            this.handleZoneSelection,
            this.props.multipleFilter.zone
          )}
          <Divider style={{ width: '100%' }} />
          {this.renderCheckList(
            capitalizeFirstLetter('Secado de leche'),
            dryingFilter,
            false,
            this.handleDryingSelection,
            this.props.multipleFilter.drying
          )}
          <ModalButtonTitle
            onClick={() => {
              if (!this.props.drying.values) this.props.fetchBusinessGrid()
              this.showModal()
            }}
          >
            {' '}
            Ver datos de seacado{' '}
          </ModalButtonTitle>
          <ChartModal
            closeModal={this.closeModal}
            show={this.state.show}
            data={this.props.businessGrid.values}
            loading={this.props.businessGrid.isFetching}
          >
            {' '}
          </ChartModal>
          <Divider style={{ width: '100%' }} />
          <SimpleCheckList
            title={capitalizeFirstLetter(`${i18n('CUSTOM_DRAWER').DRAWER_RECORDED_QUERIES}`)}
            values={this.reestructureRecordedQueries(this.props.recordedQueries.values)}
            isLoading={this.props.recordedQueries.isFetching}
            onItemSelected={item => {
              this.resetAllFilters()
              this.handleQuerySelection(item)
            }}
            selected={this.props.multipleFilter.recordedQuery}
            disabled={this.props.producersByArea.values}
          />
        </ScrollWrapper>
        {this.state.confirmationPopUp && (
          <PopUp
            title={capitalizeFirstLetter(`${i18n('POPUP').MAIN_FILTER_HINT}`)}
            onSuccess={this.handleConfirmationAccept}
            onCancel={this.closeConfirmationPopUp}
            onClose={this.closeConfirmationPopUp}
          />
        )}
      </Container>
    )
  }
}

const mapStateToProps = ({
  business,
  businessGrid,
  producersByLevel,
  provinces,
  zones,
  drying,
  multipleFilter,
  points: { basePoints, filteredKeyActive },
  filter,
  producersByBusinessId,
  producersByProvinceId,
  producersByZoneId,
  selectedProvince,
  mapStatus,
  latestFilterApplied,
  producersByArea,
  recordedQueries
}) => ({
  producersByLevel,
  business,
  businessGrid,
  drying,
  businessByLevel: mapCheckBusiness({
    resource: producersByLevel,
    filterValues: filter.business,
    filteredKeyActive,
    resourceKey: filterKeys.BUSINESS
  }),
  provinces: mapCheckResources({
    resource: provinces,
    filterValues: filter.province,
    filteredKeyActive,
    resourceKey: filterKeys.PROVINCE
  }),
  zones: mapCheckResources({
    resource: zones,
    filterValues: filter.zone,
    filteredKeyActive,
    resourceKey: filterKeys.ZONE
  }),
  filterChips:
    producersByArea.values || recordedQueries.valuesById
      ? filterBusinessByAreaPoints(
          business.values,
          producersByArea.values || recordedQueries.valuesById.productoras
        )
      : mapMultipleFilter(multipleFilter),
  multipleFilter,
  basePoints,
  filteredKeyActive,
  producersByBusinessId,
  producersByProvinceId,
  producersByZoneId,
  selectedProvince,
  mapStatus,
  latestFilterApplied,
  producersByArea,
  recordedQueries
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchBusiness,
      fetchBusinessGrid,
      fetchProvinces,
      fetchDrying,
      fetchZones,
      setSelectedBusiness,
      setSelectedProvince,
      setSelectedZone,
      setSelectedDrying,
      deleteSelectedBusiness,
      deleteSelectedProvince,
      deleteSelectedZone,
      deleteSelectedDrying,
      reset,
      setSelectedProvinces,
      setSelectedProvinceToDelete,
      fetchProducersByProvinceId,
      deleteProducersByProvinceId,
      fetchProducersByBusinessId,
      deleteProducersByBusinessId,
      fetchProducersByZoneId,
      deleteProducersByZoneId,
      deleteDryingFilterById,
      setMapStatus,
      setBusinessPoints,
      setProvincePoints,
      setZonePoints,
      setDryingPoints,
      resetFilters,
      resetPoints,
      resetProducersInfo,
      resetAreaPoints,
      resetProducersByIds,
      setLatestFilterApplied,
      resetLatestFilterApplied,
      fetchRecordedQueries,
      setSelectedRecordedQuery,
      deleteSelectedRecordedQuery,
      fetchRecordedQueriesById,
      resetSelectdRecordedQueriesById,
      resetSelectedRecordedQueries,
      setRecordedPoints,
      fetchProducersByLevel,
      restartProducersByLevel
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(SideBar)
