import { createActions } from 'redux-actions'

const { mapStatus } = createActions({
  MAP_STATUS: data => ({ data })
})

const setMapStatus = status => (dispatch, getState) => {
  const state = getState()

  dispatch(mapStatus({ ...state.mapStatus, ...status }))
}

export { setMapStatus, mapStatus }
