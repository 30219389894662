import { createActions } from 'redux-actions'

const { selectedProvinces, resetProvinces } = createActions({
  RESET_PROVINCES: () => {},
  SELECTED_PROVINCES: data => ({ data })
})

const setSelectedProvinces = status => dispatch => dispatch(selectedProvinces({ status }))
const resetSelectedProvinces = () => dispatch => dispatch(resetProvinces())

export { setSelectedProvinces, selectedProvinces, resetSelectedProvinces, resetProvinces }
