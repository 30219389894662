import { createActions } from 'redux-actions'
import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const { fetchBusinessStart, fetchBusinessSuccess, fetchBusinessError } = createActions({
  FETCH_BUSINESS_START: () => {},
  FETCH_BUSINESS_SUCCESS: data => ({ data }),
  FETCH_BUSINESS_ERROR: error => ({ error })
})

const fetchBusiness = () => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchBusinessStart())
    try {
      const response = await api.get(API_ROUTES.BUSINESS)
      dispatch(fetchBusinessSuccess({ values: response.data }))
    } catch (error) {
      dispatch(fetchBusinessError(error))
    }
  }
}

export { fetchBusiness, fetchBusinessStart, fetchBusinessSuccess, fetchBusinessError }
