import { handleActions } from 'redux-actions'
import {
  fetchProducersByZoneIdStart,
  fetchProducersByZoneIdSuccess,
  fetchProducersByZoneIdError,
  resetProducers,
  deleteProducersByZone
} from 'store/actions/producersByZoneId'

const defaultState = {
  values: [],
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchProducersByZoneIdStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchProducersByZoneIdSuccess]: (
      state,
      {
        payload: {
          data: { points }
        }
      }
    ) => ({
      values: points,
      isFetching: false,
      error: null
    }),
    [fetchProducersByZoneIdError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [resetProducers]: () => defaultState,
    [deleteProducersByZone]: (state, { payload: { producers } }) => ({
      ...state,
      values: producers
    })
  },
  defaultState
)

export default reducer
