import { createActions } from 'redux-actions'
import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'
import { setActiveFilters } from 'store/actions/filter'
import { setProducersInfo } from 'store/actions/producersInfo'

const {
  fetchDryingStart,
  fetchDryingSuccess,
  fetchDryingError,
  deleteDryingFilter
} = createActions({
  FETCH_DRYING_START: () => {},
  FETCH_DRYING_SUCCESS: data => ({ data }),
  FETCH_DRYING_ERROR: error => ({ error }),
  DELETE_DRYING_FILTER: producers => ({ producers })
})

const fetchDrying = () => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchDryingStart())
    try {
      const response = await api.get(API_ROUTES.PRODUCERS_BY_DRYING)
      dispatch(fetchDryingSuccess({ values: response.data.productoras }))
      dispatch(setActiveFilters(response.data))
      dispatch(setProducersInfo(response.data.totalProductoras))
    } catch (error) {
      //TODO catch the error provided from the api
      dispatch(fetchDryingError(error))
    }
  }
}

const deleteDryingFilterById = drying => (dispatch, getState) => {
  const {
    drying: { values }
  } = getState()
  let producersUpdated = { ...values }
  delete producersUpdated[drying.IdItem]
  dispatch(deleteDryingFilter(producersUpdated))
}

export {
  fetchDrying,
  fetchDryingStart,
  fetchDryingSuccess,
  fetchDryingError,
  deleteDryingFilterById,
  deleteDryingFilter
}
