import { createActions } from 'redux-actions'
import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'
import { setActiveFilters } from 'store/actions/filter'
import { setProducersInfo } from 'store/actions/producersInfo'
import { filterDuplicatesByKey } from 'utils/arrays'

const {
  fetchProducersByProvinceIdStart,
  fetchProducersByProvinceIdSuccess,
  fetchProducersByProvinceIdError,
  resetProducers,
  deleteProducersByProvince
} = createActions({
  FETCH_PRODUCERS_BY_PROVINCE_ID_START: () => {},
  FETCH_PRODUCERS_BY_PROVINCE_ID_SUCCESS: data => ({ data }),
  FETCH_PRODUCERS_BY_PROVINCE_ID_ERROR: error => ({ error }),
  RESET_PRODUCERS: () => {},
  DELETE_PRODUCERS_BY_PROVINCE: producers => ({ producers })
})

const fetchProducersByProvinceId = (id, simplified) => {
  return async (dispatch, getState) => {
    dispatch(fetchProducersByProvinceIdStart())
    const {
      multipleFilter: { province }
    } = getState()
    const api = await getApi()
    const provincesIds = Object.keys(province).join(',')
    try {
      const response = await api.get(`${API_ROUTES.PRODUCERS_BY_PROVINCE_ID}/${provincesIds}`)
      if (!response) throw Error()
      response.data.productoras = filterDuplicatesByKey(
        'IdUnidadProductora',
        response.data.productoras
      )
      dispatch(fetchProducersByProvinceIdSuccess({ points: response.data.productoras }))
      if (!simplified) {
        dispatch(setActiveFilters(response.data))
        dispatch(setProducersInfo(response.data.totalProductoras))
      }
    } catch (error) {
      dispatch(fetchProducersByProvinceIdError(error))
    }
  }
}

const resetProducersByProvinceId = () => dispatch => dispatch(resetProducers())

const deleteProducersByProvinceId = province => (dispatch, getState) => {
  const {
    producersByProvinceId: { values }
  } = getState()
  let producersUpdated = { ...values }
  delete producersUpdated[province.IdItem]
  dispatch(deleteProducersByProvince(producersUpdated))
}

export {
  fetchProducersByProvinceId,
  fetchProducersByProvinceIdStart,
  fetchProducersByProvinceIdSuccess,
  fetchProducersByProvinceIdError,
  resetProducersByProvinceId,
  resetProducers,
  deleteProducersByProvinceId,
  deleteProducersByProvince
}
