import { createActions } from 'redux-actions'
import { setProducersInfo } from 'store/actions/producersInfo'
import { setActiveFilters } from 'store/actions/filter'
import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const {
  fetchRecordedQueriesStart,
  fetchRecordedQueriesSuccess,
  fetchRecordedQueriesError,

  fetchRecordedQueriesByIdStart,
  fetchRecordedQueriesByIdSuccess,
  fetchRecordedQueriesByIdError,
  resetRecordedQueriesById,

  resetAllRecordedQueries
} = createActions({
  FETCH_RECORDED_QUERIES_START: () => {},
  FETCH_RECORDED_QUERIES_SUCCESS: data => ({ data }),
  FETCH_RECORDED_QUERIES_ERROR: error => ({ error }),

  FETCH_RECORDED_QUERIES_BY_ID_START: () => {},
  FETCH_RECORDED_QUERIES_BY_ID_SUCCESS: data => ({ data }),
  FETCH_RECORDED_QUERIES_BY_ID_ERROR: error => ({ error }),
  RESET_RECORDED_QUERIES_BY_ID: () => {},

  RESET_ALL_RECORDED_QUERIES: () => {}
})

const fetchRecordedQueries = () => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchRecordedQueriesStart())
    try {
      const response = await api.get(API_ROUTES.RECORDED_QUERIES)
      dispatch(fetchRecordedQueriesSuccess({ values: response.data }))
    } catch (error) {
      dispatch(fetchRecordedQueriesError(error))
    }
  }
}

const fetchRecordedQueriesById = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchRecordedQueriesByIdStart())
    try {
      const response = await api.get(`${API_ROUTES.RECORDED_QUERIES_BY_ID}/${id}`)
      dispatch(fetchRecordedQueriesByIdSuccess({ values: response.data }))
      dispatch(setProducersInfo(response.data.totalProductoras))
      dispatch(setActiveFilters(response.data))
    } catch (error) {
      dispatch(fetchRecordedQueriesByIdError(error))
    }
  }
}

const resetSelectdRecordedQueriesById = () => dispatch => dispatch(resetRecordedQueriesById())

const resetAllSelectdRecordedQueries = () => dispatch => dispatch(resetAllRecordedQueries())

export {
  fetchRecordedQueries,
  fetchRecordedQueriesStart,
  fetchRecordedQueriesSuccess,
  fetchRecordedQueriesError,
  fetchRecordedQueriesById,
  fetchRecordedQueriesByIdStart,
  fetchRecordedQueriesByIdSuccess,
  fetchRecordedQueriesByIdError,
  resetSelectdRecordedQueriesById,
  resetRecordedQueriesById,
  resetAllSelectdRecordedQueries,
  resetAllRecordedQueries
}
