import { createActions } from 'redux-actions'
import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'
import { setActiveFilters } from 'store/actions/filter'
import { setProducersInfo } from 'store/actions/producersInfo'
import { filterDuplicatesByKey } from 'utils/arrays'

const {
  fetchProducersByBusinessIdStart,
  fetchProducersByBusinessIdSuccess,
  fetchProducersByBusinessIdError,
  resetProducers,
  deleteProducersByBusiness
} = createActions({
  FETCH_PRODUCERS_BY_BUSINESS_ID_START: () => {},
  FETCH_PRODUCERS_BY_BUSINESS_ID_SUCCESS: data => ({ data }),
  FETCH_PRODUCERS_BY_BUSINESS_ID_ERROR: error => ({ error }),
  RESET_PRODUCERS: () => {},
  DELETE_PRODUCERS_BY_BUSINESS: producers => ({ producers })
})

const fetchProducersByBusinessId = (id, simplified) => {
  return async (dispatch, getState) => {
    dispatch(fetchProducersByBusinessIdStart())
    const api = await getApi()
    const {
      multipleFilter: { business }
    } = getState()
    const ids = Object.keys(business)
    try {
      const response = await api.post(`${API_ROUTES.PRODUCERS_BY_BUSINESS_ID}`, { ids })
      if (!response) throw Error()
      response.data.productoras = filterDuplicatesByKey(
        'IdUnidadProductora',
        response.data.productoras
      )
      dispatch(
        fetchProducersByBusinessIdSuccess({
          points: response.data.productoras
        })
      )
      if (!simplified) {
        dispatch(setActiveFilters(response.data))
        dispatch(setProducersInfo(response.data.totalProductoras))
      }
    } catch (error) {
      //TODO catch the error provided from the api
      dispatch(fetchProducersByBusinessIdError(error))
    }
  }
}

const resetProducersByBusinessId = () => dispatch => dispatch(resetProducers())

const deleteProducersByBusinessId = business => (dispatch, getState) => {
  const {
    producersByBusinessId: { values }
  } = getState()
  let producersUpdated = { ...values }
  delete producersUpdated[business.IdItem]
  dispatch(deleteProducersByBusiness(producersUpdated))
}

export {
  fetchProducersByBusinessId,
  fetchProducersByBusinessIdStart,
  fetchProducersByBusinessIdSuccess,
  fetchProducersByBusinessIdError,
  resetProducersByBusinessId,
  resetProducers,
  deleteProducersByBusinessId,
  deleteProducersByBusiness
}
