import React from 'react'
import ProducersTable from 'components/ProducersTable'
import { Wrapper, ExpandedWrapper } from './styled'
import ButtonRounded from 'components/ButtonRounded'
import Icon, { IconNames } from 'components/Icons'
import theme from 'config/theme'

class ProducersTableHidden extends React.Component {
  state = {
    expanded: false
  }

  toggleExpandedState = () => this.setState({ expanded: !this.state.expanded })

  render() {
    return (
      <Wrapper>
        {this.state.expanded && (
          <ExpandedWrapper>
            <ProducersTable {...this.props} />
          </ExpandedWrapper>
        )}
        <ButtonRounded onClick={this.toggleExpandedState} backgroundColor={theme.colors.green}>
          <Icon name={IconNames.Table} color={theme.colors.white} size={30} />
        </ButtonRounded>
      </Wrapper>
    )
  }
}

export default ProducersTableHidden
