import { createActions } from 'redux-actions'
import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'
import { setActiveFilters } from 'store/actions/filter'
import { setProducersInfo } from 'store/actions/producersInfo'
import { filterDuplicatesByKey } from 'utils/arrays'

const {
  fetchProducersByZoneIdStart,
  fetchProducersByZoneIdSuccess,
  fetchProducersByZoneIdError,
  resetProducers,
  deleteProducersByZone
} = createActions({
  FETCH_PRODUCERS_BY_ZONE_ID_START: () => {},
  FETCH_PRODUCERS_BY_ZONE_ID_SUCCESS: data => ({ data }),
  FETCH_PRODUCERS_BY_ZONE_ID_ERROR: error => ({ error }),
  RESET_PRODUCERS: () => {},
  DELETE_PRODUCERS_BY_ZONE: producers => ({ producers })
})

const fetchProducersByZoneId = (id, simplified) => {
  return async (dispatch, getState) => {
    dispatch(fetchProducersByZoneIdStart())
    const api = await getApi()
    const {
      multipleFilter: { zone }
    } = getState()
    const zoneIds = Object.keys(zone).join(',')
    try {
      const response = await api.get(`${API_ROUTES.PRODUCERS_BY_ZONE_ID}/${zoneIds}`)
      if (!response) throw Error()
      response.data.productoras = filterDuplicatesByKey(
        'IdUnidadProductora',
        response.data.productoras
      )
      dispatch(fetchProducersByZoneIdSuccess({ points: response.data.productoras }))
      if (!simplified) {
        dispatch(setActiveFilters(response.data))
        dispatch(setProducersInfo(response.data.totalProductoras))
      }
    } catch (error) {
      //TODO catch the error provided from the api
      dispatch(fetchProducersByZoneIdError(error))
    }
  }
}

const resetProducersByZoneId = () => dispatch => dispatch(resetProducers())

const deleteProducersByZoneId = zone => (dispatch, getState) => {
  const {
    producersByZoneId: { values }
  } = getState()
  let producersUpdated = { ...values }
  delete producersUpdated[zone.IdItem]
  dispatch(deleteProducersByZone(producersUpdated))
}

export {
  fetchProducersByZoneId,
  fetchProducersByZoneIdStart,
  fetchProducersByZoneIdSuccess,
  fetchProducersByZoneIdError,
  resetProducersByZoneId,
  resetProducers,
  deleteProducersByZoneId,
  deleteProducersByZone
}
