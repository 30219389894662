import { createActions } from 'redux-actions'

const { setFilterApplied, resetFilterApplied } = createActions({
  SET_FILTER_APPLIED: key => ({ key }),
  RESET_FILTER_APPLIED: () => {}
})

const setLatestFilterApplied = key => dispatch => dispatch(setFilterApplied(key))

const resetLatestFilterApplied = () => dispatch => dispatch(resetFilterApplied())

export { setLatestFilterApplied, setFilterApplied, resetLatestFilterApplied, resetFilterApplied }
