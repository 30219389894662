import { createActions } from 'redux-actions'
import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const { fetchProvincesStart, fetchProvincesSuccess, fetchProvincesError } = createActions({
  FETCH_PROVINCES_START: () => {},
  FETCH_PROVINCES_SUCCESS: data => ({ data }),
  FETCH_PROVINCES_ERROR: error => ({ error })
})

const fetchProvinces = () => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchProvincesStart())
    try {
      const response = await api.get(API_ROUTES.PROVINCES)
      dispatch(fetchProvincesSuccess({ values: response.data }))
    } catch (error) {
      //TODO catch the error provided from the api
      dispatch(fetchProvincesError(error))
    }
  }
}

export { fetchProvinces, fetchProvincesStart, fetchProvincesSuccess, fetchProvincesError }
