import React from 'react'
import { Chip } from '@material-ui/core'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin: 4px;
  & > div {
    border: 1.5px solid ${({ color, theme }) => color || theme.colors.lamborghiniDiabloPurble};
    color: white;
    background: ${({ color, theme }) => color || theme.colors.lamborghiniDiabloPurble};

    &:focus {
      background: ${({ color, theme }) => color || theme.colors.lamborghiniDiabloPurble};
    }

    &:hover {
      background: ${({ color, theme }) => color || theme.colors.lamborghiniDiabloPurble};
    }
  }
`

const CustomChip = ({ label, onDelete, color }) => (
  <Wrapper color={color}>
    <Chip label={label} onDelete={() => onDelete()} deleteIcon variant="default" />
  </Wrapper>
)

export default CustomChip
