import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.mastelloneGreen};
`

export const ImageWrapper = styled.div`
  width: 100%;
  text-align: center;
`

export const StyledImg = styled.img`
  width: 20%;
  min-width: 250px;
  max-width: 350px;
`
export const FormContainer = styled.form`
  width: 30%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  min-width: 300px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 350px;
  max-height: 300px;
  min-height: 250px;
  box-shadow: 7px 13px 17px -10px rgba(0, 0, 0, 0.75);
  border-radius: 30px;
`

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  box-sizing: border-box;
  max-width: 600px;
  margin: auto;
`

export const ButtonWrapper = styled.div`
  margin: ${({ margin }) => margin}px 0;
  margin-bottom: 15px;
`

export const ErrorLabel = styled.label`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`
