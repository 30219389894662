import styled from 'styled-components'
import mediaQueries from 'config/media-queries'

export const StylizedContainer = styled.div`
  width: 60%;
  height: 50%;
  background: ${({ theme }) => theme.colors.white};
  margin-left: auto;
  margin-right: auto;
  padding: 15px 20px 15px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  max-width: 800px;

  ${mediaQueries.tablet`
	 width: 90%;
  `};
`

export const CrossImage = styled.img`
  width: 15px;
  height: 15px;
  top: 15px;
  right: 20px;
  cursor: pointer;
`

export const StyledDivHeaderWrapper = styled.div`
  text-align: right;
`

export const StyledDivFooterWrapper = styled.div`
  margin-top: auto;
  margin-left: auto;
`

export const StyledTitle = styled.text`
  font-size: 20px;
  font-weight: 500;
  display: block;
`

export const StyledSubTitle = styled.text`
  font-size: 17px;
  font-weight: 300;
  display: block;
  margin-block-end: 10px;
`
export const ButtonWrapper = styled.div`
  margin: ${({ margin }) => margin}px 0;
  margin-bottom: 15px;
  width: 100px;
  margin-left: auto;
`

export const StyledForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const ErrorLabel = styled.label`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`
