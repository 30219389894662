import React from 'react'
import CustomChip from 'components/CustomChip'
import { getShorterString } from 'utils/strings'
import styled from 'styled-components'
import theme from 'config/theme'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const getLabel = label => (label.length > 20 ? getShorterString(label, 20) : label)

const ChipList = ({ values, onChipDelete }) => (
  <Container>
    {values.map((element, index) => (
      <CustomChip
        key={index}
        label={getLabel(element.label)}
        onDelete={() => onChipDelete(element)}
        color={element.ColorHex || theme.colors.lamborghiniDiabloPurple}
      />
    ))}
  </Container>
)

export default ChipList
