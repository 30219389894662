import React from 'react'
import Logo from 'assets/imgs/logo.png'
import i18n from 'services/i18n'
import {
  Container,
  Wrapper,
  ImageWrapper,
  StyledImgLaserenisima,
  StyledLabel,
  Redirect
} from './styled'

class NotFound extends React.Component {
  render() {
    return (
      <Container>
        <Wrapper>
          <ImageWrapper>
            <StyledImgLaserenisima src={Logo} />
          </ImageWrapper>
          <StyledLabel size={250}>404</StyledLabel>
          <StyledLabel>UPS! {i18n('404').ROUTE_NOT_FOUND}</StyledLabel>
          <Redirect href={'/'}>{i18n('404').REDIRECT_MESSAGE}</Redirect>
        </Wrapper>
      </Container>
    )
  }
}

export default NotFound
