import { handleActions } from 'redux-actions'
import {
  fetchProducersByProvinceIdStart,
  fetchProducersByProvinceIdSuccess,
  fetchProducersByProvinceIdError,
  resetProducers,
  deleteProducersByProvince
} from 'store/actions/producersByProvinceId'

const defaultState = {
  values: [],
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchProducersByProvinceIdStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchProducersByProvinceIdSuccess]: (
      state,
      {
        payload: {
          data: { points }
        }
      }
    ) => ({
      values: points,
      isFetching: false,
      error: null
    }),
    [fetchProducersByProvinceIdError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [resetProducers]: () => defaultState,
    [deleteProducersByProvince]: (state, { payload: { producers } }) => ({
      ...state,
      values: producers
    })
  },
  defaultState
)

export default reducer
