import styled from 'styled-components'
import mediaQueries from 'config/media-queries'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  ${({ mobileHidden }) =>
    mobileHidden &&
    mediaQueries.mobile`
		display: none;
	`}
`

export const LogoWrapper = styled.div`
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.mastelloneGreen};
`

export const StyledImg = styled.img`
  height: 75%;
`

export const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  -ms-overflow-style: none;
`
export const ModalButtonTitle = styled.label`
  font-size: 20px;
  padding: 10px 0 10px 11px;
  cursor: pointer;
  margin: 10%;
  border: 1px solid #e9e6e6;
  padding: 10px;
  box-shadow: 3px 2px 0px #c7c7c7;
  font-size: 16px;
`
