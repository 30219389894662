import { createActions } from 'redux-actions'
import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const { fetchBusinessGridStart, fetchBusinessGridSuccess, fetchBusinessGridError } = createActions({
  FETCH_BUSINESS_GRID_START: () => {},
  FETCH_BUSINESS_GRID_SUCCESS: data => ({ data }),
  FETCH_BUSINESS_GRID_ERROR: error => ({ error })
})

const fetchBusinessGrid = () => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchBusinessGridStart())
    try {
      const response = await api.get(`${API_ROUTES.BUSINESS_GRID_INFO}`)
      dispatch(fetchBusinessGridSuccess({ values: response.data }))
    } catch (error) {
      dispatch(fetchBusinessGridError(error))
    }
  }
}

export {
  fetchBusinessGrid,
  fetchBusinessGridStart,
  fetchBusinessGridSuccess,
  fetchBusinessGridError
}
