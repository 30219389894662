import { handleActions } from 'redux-actions'
import {
  fetchRecordedQueriesStart,
  fetchRecordedQueriesSuccess,
  fetchRecordedQueriesError,
  fetchRecordedQueriesByIdStart,
  fetchRecordedQueriesByIdSuccess,
  fetchRecordedQueriesByIdError,
  resetRecordedQueriesById,
  resetAllRecordedQueries
} from 'store/actions/recordedQueries'

const defaultState = {
  values: null,
  error: null,
  isFetching: false,

  valuesById: null,
  errorById: null,
  isFetchingById: false
}

const reducer = handleActions(
  {
    [fetchRecordedQueriesStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchRecordedQueriesSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchRecordedQueriesError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error
    }),

    [fetchRecordedQueriesByIdStart]: state => ({
      ...state,
      isFetchingById: true,
      errorById: null
    }),
    [fetchRecordedQueriesByIdSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      valuesById: values,
      isFetchingById: false,
      errorById: null
    }),
    [fetchRecordedQueriesByIdError]: (state, { error }) => ({
      ...state,
      isFetchingById: false,
      errorById: error
    }),
    [resetRecordedQueriesById]: state => ({
      ...state,
      valuesById: null,
      errorById: null,
      isFetchingById: false
    }),

    [resetAllRecordedQueries]: () => defaultState
  },
  defaultState
)

export default reducer
