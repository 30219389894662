import { createSelector } from 'reselect'

const mapCheckResources = createSelector(
  data => data,
  data => {
    if (data.filteredKeyActive !== data.resourceKey) {
      const formattedData = { ...data.resource }
      if (data.filterValues) {
        formattedData.values = data.resource.values.filter(
          r => data.filterValues.indexOf(r.IdItem) > -1
        )
      }
      return formattedData
    }
    return data.resource
  }
)

export default mapCheckResources

export const mapCheckBusiness = createSelector(
  data => data,
  data => {
    if (data.filteredKeyActive !== data.resourceKey) {
      const formattedData = JSON.parse(JSON.stringify({ ...data.resource }))
      if (data.filterValues) {
        formattedData.values[`lvl1`] = data.resource.baseValues.lvl1.filter(
          r => data.filterValues.indexOf(r.IdItem) > -1
        )
        formattedData.values[`lvl2`] = data.resource.baseValues.lvl2.filter(
          r => data.filterValues.indexOf(r.IdItem) > -1
        )
        formattedData.values[`lvl3`] = data.resource.baseValues.lvl3.filter(
          r => data.filterValues.indexOf(r.IdItem) > -1
        )
      }
      return formattedData
    }
    return JSON.parse(JSON.stringify(data.resource))
  }
)
