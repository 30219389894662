import { createSelector } from 'reselect'
import filterKeys from 'constants/filterKeys'
import { concatArrayValues } from 'utils/arrays'

const mapMultipleFilter = createSelector(
  multipleFilter => multipleFilter,
  ({ business, province, zone, drying }) => {
    const businessFormatted = formatBusinessFilter(business)
    const provinceFormatted = formatFilter(province, filterKeys.PROVINCE)
    const zoneFormatted = formatFilter(zone, filterKeys.ZONE)
    const dryingFormatted = formatFilter(drying, filterKeys.DRYING)
    return concatArrayValues([businessFormatted, provinceFormatted, zoneFormatted, dryingFormatted])
  }
)

const formatBusinessFilter = filterValues =>
  Object.values(filterValues).map(({ IdItem, Descripcion, ColorHex }) => ({
    IdItem,
    label: Descripcion,
    ColorHex,
    filterKey: filterKeys.BUSINESS
  }))

const formatFilter = (filterValues, filterKey) =>
  Object.values(filterValues).map(({ IdItem, Descripcion }) => ({
    IdItem,
    label: Descripcion,
    filterKey
  }))

export const filterBusinessByAreaPoints = (business, areaBusiness) => {
  const areaBusinessIds = getAreaBusinessIds(areaBusiness)
  const filteredBusiness = business.filter(e => areaBusinessIds.includes(e.IdItem))
  const formattedBusiness = formatBusinessFilter(filteredBusiness)
  return formattedBusiness
}

const getAreaBusinessIds = areaBusiness =>
  areaBusiness.reduce((accum, actual) => {
    const businessId = actual.ListaIdEmpresa[0]
    if (!accum.includes(businessId)) {
      accum.push(businessId)
    }
    return accum
  }, [])

export default mapMultipleFilter
