import { createActions } from 'redux-actions'
import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const { fetchZonesStart, fetchZonesSuccess, fetchZonesError } = createActions({
  FETCH_ZONES_START: () => {},
  FETCH_ZONES_SUCCESS: data => ({ data }),
  FETCH_ZONES_ERROR: error => ({ error })
})

const fetchZones = () => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchZonesStart())
    try {
      const response = await api.get(API_ROUTES.ZONES)
      dispatch(fetchZonesSuccess({ values: response.data }))
    } catch (error) {
      //TODO catch the error provided from the api
      dispatch(fetchZonesError(error))
    }
  }
}

export { fetchZones, fetchZonesStart, fetchZonesSuccess, fetchZonesError }
