import { handleActions } from 'redux-actions'
import {
  fetchProducersByBusinessIdStart,
  fetchProducersByBusinessIdSuccess,
  fetchProducersByBusinessIdError,
  resetProducers,
  deleteProducersByBusiness
} from 'store/actions/producersByBusinessId'

const defaultState = {
  values: [],
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchProducersByBusinessIdStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchProducersByBusinessIdSuccess]: (
      state,
      {
        payload: {
          data: { points }
        }
      }
    ) => ({
      values: points,
      isFetching: false,
      error: null
    }),
    [fetchProducersByBusinessIdError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [resetProducers]: () => defaultState,
    [deleteProducersByBusiness]: (state, { payload: { producers } }) => ({
      ...state,
      values: producers
    })
  },
  defaultState
)

export default reducer
