import { handleActions } from 'redux-actions'
import { setRawPoints, resetPointsReducer } from 'store/actions/points'

const defaultState = {
  basePoints: []
}

const reducer = handleActions(
  {
    [setRawPoints]: (state, { payload: { points, key } }) => ({
      basePoints: Array.isArray(points) ? points : [],
      filteredKeyActive: key
    }),
    [resetPointsReducer]: () => defaultState
  },
  defaultState
)

export default reducer
