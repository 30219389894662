import React from 'react'
import mediaQueries from 'config/media-queries'
import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  width: 40px;
  top: 120px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${mediaQueries.mobile`
    display: none;
  `}
`

class OptionsMenu extends React.Component {
  state = {
    expanded: false
  }

  toggleExpandedState = () => this.setState({ expanded: !this.state.expanded })

  render() {
    return <Container>{this.props.children}</Container>
  }
}

export default OptionsMenu
