const drawingManagerConfig = (selectedShape, google, theme, map) => ({
  drawingMode: null,
  drawingControl: selectedShape ? false : true,
  drawingControlOptions: {
    position: google.maps.ControlPosition.TOP_CENTER,
    drawingModes: ['circle']
  },

  overlayCompleteEvent: {
    overlay: ['circle']
  },

  circleOptions: {
    fillColor: theme.colors.mastelloneGreen,
    fillOpacity: 0.5,
    strokeWeight: 2,
    clickable: true,
    editable: false,
    zIndex: 1
  },
  map
})

export default drawingManagerConfig
