const getBaseUrl = () => {
  // DEV ENVIROMENT / DEFAULT ENVIROMENT
  return 'http://mlacteo.mastellone.com.ar/api/'
  //return 'http://mastdesarrollo.terrand-audits.com/api/'
}
const API_ROUTES = {
  BASE_URL: getBaseUrl(),
  LOGIN: 'login',
  BUSINESS: 'datos/empresas',
  PRODUCERS_BY_BUSINESS_ID: 'productoras/emp',
  PROVINCES: 'datos/provincias',
  PRODUCERS_BY_PROVINCE_ID: 'productoras/prov',
  ZONES: 'datos/zonas',
  PRODUCERS_BY_ZONE_ID: 'productoras/zona',
  PRODUCERS_BY_PRODUCER_ID: 'productoras',
  PRODUCERS_BUSINESS_BY_PRODUCER_ID: 'productoras/empresa-adicional',
  PRODUCERS_BY_AREA: 'productoras/radio',
  PRODUCERS_BY_IDS: 'productoras/usuario',
  PRODUCERS_OBS: 'obs',
  PRODUCERS_BY_LEVEL: 'datos/nivel',
  RECORDED_QUERIES: 'datos/consultas',
  RECORDED_QUERIES_BY_ID: 'productoras/consultas',
  PRODUCERS_BY_DRYING: 'productoras/secado',
  BUSINESS_GRID_INFO: 'datos/grilla-empresas-secado'
}

export default API_ROUTES
