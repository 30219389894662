import sessionKeys from 'config/sessionKeys'

export const isUserLoggedIn = () => getCacheString(`@mastelloneMap/${sessionKeys.TOKEN}`)

export const userCanEdit = () => JSON.parse(localStorage.getItem('@mastelloneMap/edit'))

export const userCanEditIndustry = () =>
  JSON.parse(localStorage.getItem('@mastelloneMap/editIndustrias'))

export const saveLocalStatus = ({ data }) => {
  localStorage.setItem(`@mastelloneMap/${sessionKeys.TOKEN}`, data.token)
  localStorage.setItem(`@mastelloneMap/${sessionKeys.EDIT}`, data.canEdit)
  localStorage.setItem(`@mastelloneMap/${sessionKeys.EDIT_INDUSTRIAS}`, data.canEditIndustria)
}

export const removeLocalStatus = () => {
  localStorage.removeItem(`@mastelloneMap/${sessionKeys.TOKEN}`)
  localStorage.removeItem(`@mastelloneMap/${sessionKeys.EDIT}`)
  localStorage.removeItem(`@mastelloneMap/${sessionKeys.EDIT_INDUSTRIAS}`)
}

export const saveCachedIds = ids => localStorage.setItem(`@mastelloneMap/${sessionKeys.IDS}`, ids)

export const removeCachedIds = () => localStorage.removeItem(`@mastelloneMap/${sessionKeys.IDS}`)

export const getCacheIds = () => getCacheString(`@mastelloneMap/${sessionKeys.IDS}`)

export const getCacheString = key => localStorage.getItem(key)
