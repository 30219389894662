//To add default point or hardcored point we have to use this data format and add them to
// the getPoints function in components/Map

export const basePoints = [
  {
    IdUnidadProductora: '999998',
    Latitud: '-34.603667',
    Litros: 'none',
    Longitud: '-58.381583',
    Nombre: 'obeisco',
    Precio: 'none'
  }
]

export const basePosition = { lat: -36.053994, lng: -64.938815 }
