import { handleActions } from 'redux-actions'
import { selectedProvinces, resetProvinces } from 'store/actions/selectedProvince'
import { basePosition } from 'config/map'

const defaultState = {
  name: '',
  id: '',
  coordinates: basePosition,
  boundries: null
}

const reducer = handleActions(
  {
    [selectedProvinces]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => status,
    [resetProvinces]: () => defaultState
  },
  defaultState
)

export default reducer
