import { createActions } from 'redux-actions'
import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'
import { saveLocalStatus } from 'utils/session'

const { loginStart, loginSuccess, loginError, loginResetState } = createActions({
  LOGIN_START: () => {},
  LOGIN_SUCCESS: data => ({ data }),
  LOGIN_ERROR: error => ({ error }),
  LOGIN_RESET_STATE: () => {}
})

const login = (username, password) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(loginStart())
    const authData = {
      usuario: username,
      clave: password,
      raizFuncion: 'WEB'
    }
    try {
      const response = await api.post(API_ROUTES.LOGIN, authData)

      saveLocalStatus(response)
      dispatch(loginSuccess())
    } catch (error) {
      //TODO catch the error provided from the api
      dispatch(loginError(error))
    }
  }
}

const resetLoginReducer = () => dispatch => dispatch(loginResetState())

export { login, loginStart, loginSuccess, loginError, loginResetState, resetLoginReducer }
