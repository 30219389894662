/* eslint-disable max-len */
const size = 45

const formattSvgIcon = svg => 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg)

export const emptyBottle = color =>
  formattSvgIcon(`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" size="${size}" height="${size}" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<style type="text/css">
 
   .st0{fill-rule:evenodd;clip-rule:evenodd;fill:${color};stroke:#070308;stroke-size:1.2853;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
 
   .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#070308;stroke-size:1.2853;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
</style>
<g id="Botella_verde">
 <g id="botella">
   <path class="st0" d="M57.2,4.14l0.39,0.39c-0.01,0.73-0.02,1.43-0.02,2.1c1.21,0.1,1.13,1.88,0.02,1.91
     c0.04,1.69,0.13,3.21,0.27,4.65c0.55,5.45,1.73,9.58,4.37,16.54c1.83,4.94,3.06,8.45,3.04,13.84l-0.19,42.25
     c-0.01,3.02-0.37,4.91-1.25,6.23c-1.87,2.79-8.03,3.86-14.13,3.82c-5.92-0.04-11.78-1.11-13.59-3.82
     c-0.89-1.32-1.24-3.21-1.25-6.23l-0.19-42.25c-0.02-5.4,1.21-8.9,3.04-13.84c2.64-6.96,3.82-11.09,4.37-16.54
     c0.14-1.44,0.23-2.96,0.27-4.65c-1.03-0.03-1.05-1.91,0.01-1.91c0.03,0,0-1.92,0-2.1l0.39-0.39C48.46,3.95,51.48,3.95,57.2,4.14z"
     />
 </g>
</g>
<g id="Leche_Blanca">
 <path class="st1" d="M34.81,74.97l0.05,10.85c0.01,3.02,0.36,4.91,1.25,6.23c1.81,2.71,7.67,3.78,13.59,3.82
   c6.1,0.04,12.26-1.03,14.13-3.82c0.88-1.32,1.24-3.21,1.25-6.23l0.05-10.85H34.81z"/>
</g>
</svg>
`)

export const halfBottle = color =>
  formattSvgIcon(`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" size="${size}" height="${size}" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<style type="text/css">
 
   .st0{fill-rule:evenodd;clip-rule:evenodd;fill:${color};stroke:#000000;stroke-size:1.2853;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
 
   .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FDFFFD;stroke:#000000;stroke-size:1.2853;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
</style>
<g id="Botella_verde">
 <g id="botella">
   <path class="st0" d="M55.2,3.1l0.4,0.4c0,0.7,0,1.4,0,2.1c1.2,0.1,1.1,1.9,0,1.9c0,1.7,0.1,3.2,0.3,4.7c0.5,5.4,1.7,9.6,4.4,16.5
     c1.8,4.9,3.1,8.4,3,13.8l-0.2,42.2c0,3-0.4,4.9-1.2,6.2c-1.9,2.8-8,3.9-14.1,3.8c-5.9,0-11.8-1.1-13.6-3.8
     c-0.9-1.3-1.2-3.2-1.2-6.2l-0.2-42.2c0-5.4,1.2-8.9,3-13.8c2.6-7,3.8-11.1,4.4-16.5c0.1-1.4,0.2-3,0.3-4.7c-1,0-1-1.9,0-1.9
     c0,0,0-1.9,0-2.1l0.4-0.4C46.5,3,49.5,3,55.2,3.1z"/>
 </g>
</g>
<g id="Leche_blanco">
 <path class="st1" d="M32.7,48.7l0.2,36.1c0,3,0.4,4.9,1.2,6.2c1.8,2.7,7.7,3.8,13.6,3.8c6.1,0,12.3-1,14.1-3.8
   c0.9-1.3,1.2-3.2,1.2-6.2l0.2-36.1H32.7z"/>
</g>
</svg>
`)

export const fullBottle = color =>
  formattSvgIcon(`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" size="${size}" height="${size}" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<style type="text/css">
 
   .st0{fill-rule:evenodd;clip-rule:evenodd;fill:${color};stroke:#070308;stroke-size:1.2853;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
 
   .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#070308;stroke-size:1.2853;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
</style>
<g id="Botella_verde">
 <g id="botella">
   <path class="st0" d="M57.2,4.14l0.39,0.39c-0.01,0.73-0.02,1.43-0.02,2.1c1.21,0.1,1.13,1.88,0.02,1.91
     c0.04,1.69,0.13,3.21,0.27,4.65c0.55,5.45,1.73,9.58,4.37,16.54c1.83,4.94,3.06,8.45,3.04,13.84l-0.19,42.25
     c-0.01,3.02-0.37,4.91-1.25,6.23c-1.87,2.79-8.03,3.86-14.13,3.82c-5.92-0.04-11.78-1.11-13.59-3.82
     c-0.89-1.32-1.24-3.21-1.25-6.23l-0.19-42.25c-0.02-5.4,1.21-8.9,3.04-13.84c2.64-6.96,3.82-11.09,4.37-16.54
     c0.14-1.44,0.23-2.96,0.27-4.65c-1.03-0.03-1.05-1.91,0.01-1.91c0.03,0,0-1.92,0-2.1l0.39-0.39C48.46,3.95,51.48,3.95,57.2,4.14z"
     />
 </g>
</g>
<g id="Leche_Blanca">
 <path class="st1" d="M35.45,36.71c-0.5,2.09-0.78,4.25-0.78,6.86l0.19,42.25c0.01,3.02,0.36,4.91,1.25,6.23
   c1.81,2.71,7.67,3.78,13.59,3.82c6.1,0.04,12.26-1.03,14.13-3.82c0.88-1.32,1.24-3.21,1.25-6.23l0.19-42.25
   c0.01-2.6-0.28-4.76-0.78-6.86H35.45z"/>
</g>
</svg>
`)

export const enterprise = color =>
  formattSvgIcon(`<svg version="1.1" id="Industria" size="${size}" height="${size}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<style type="text/css">
 .st0{fill:${color};stroke:#000000;stroke-size:0.3961;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
</style>
<path class="st0" d="M88.6,35.5l-21.5,7.3c-0.1,0.1-0.3-0.1-0.3-0.2v-7.3c0-0.2-0.2-0.3-0.3-0.2l-21.4,8.5c-0.2,0.1-0.3-0.1-0.3-0.2
 V15c0-0.1-0.1-0.2-0.2-0.2h-8c-0.1,0-0.2,0.1-0.2,0.2v30c0,0.1-0.1,0.2-0.2,0.2h-1.7c-0.1,0-0.2-0.1-0.2-0.2V12.1
 c0-0.1-0.1-0.2-0.2-0.2h-7.5c-0.1,0-0.2,0.1-0.2,0.2v4.4c0,0.1-0.1,0.2-0.2,0.2h-8.8c-0.1,0-0.2,0.1-0.2,0.2v28.6
 c0,0.1-0.1,0.2-0.2,0.2h-5.4c-0.1,0-0.2,0.1-0.2,0.2v34c0,0.1,0.1,0.2,0.2,0.2h77.4c0.1,0,0.2-0.1,0.2-0.2V35.7
 C88.9,35.6,88.8,35.5,88.6,35.5z M20.4,68.3V54.6h12.4v13.8H20.4z M56.9,68.3H44.5V54.6h12.4V68.3z M80.3,68.3H67.9V54.6h12.4V68.3z
 "/>
</svg>
`)
