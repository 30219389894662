import React, { Fragment } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import theme from 'config/theme'
import GlobalStyle from 'config/globalStyle'
import store from './store'
import i18n from 'services/i18n'
import Login from 'containers/authentication/login'
import Home from 'containers/map'
import NotFound from 'containers/NotFound'
import PrivateRoute from 'components/PrivateRoute'

const browserHistory = createBrowserHistory()

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Fragment>
        <Router history={browserHistory}>
          <Switch>
            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute path={`/${i18n('ROUTES').ROUTE_MAP}`} component={Home} />
            <Route path={`/${i18n('ROUTES').ROUTE_LOGIN}`} component={Login} />
            <Route exact path={`/404`} component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </Router>
        <GlobalStyle />
      </Fragment>
    </ThemeProvider>
  </Provider>
)

export default App
