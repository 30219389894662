import styled from 'styled-components'
import mediaQueries from 'config/media-queries'

export const StyledAppBar = styled.div`
  width: 100%
  transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  margin-left:${({ drawerWidth }) => drawerWidth};
  color: #fff;
  background-color: ${({ theme }) => theme.colors.mastelloneGreen};
  top: 0;
  left: auto;
  right: 0;
  position: fixed;
  display: flex;
  z-index: 1100;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;

  & > div {
    padding-left: 0;
    padding-right: 9px;
  }

  ${mediaQueries.mobile`
  width: ${({ drawerWidth }) => ` (100% - ${drawerWidth}px)`} ;
`}
`

export const StyledTitle = styled.div`
  ${mediaQueries.mobile`
  margin-left: 0px;
`};
`

export const StyledIconButton = styled.button`
  display: none;
  margin-right: 20px;
  border: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  -moz-appearance: none;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  flex: 0 0 auto;
  color: inherit;
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;

  ${mediaQueries.mobile`
    display: inherit;
  `}
`

export const StyledExitButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const InfoContainer = styled(Container)`
  ${mediaQueries.mobile`
    display: none;
  `}
`

export const ReferenceImage = styled.img`
  height: 30px;
`
export const Separator = styled.div`
  padding: 0 15px;
`

export const LogoWrapper = styled.div`
  height: 64px;
  min-height: 64px;
  max-width: 240px;
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.mastelloneGreen};

  ${mediaQueries.mobile`
    display: none;
  `}
`

export const StyledImg = styled.img`
  height: 75%;
`
