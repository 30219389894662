import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ExpandedWrapper = styled.div`
  position: absolute;
  width: 400px;
  height: 200px;
  right: 0;
  pointer-events: none;
`
