import { createActions } from 'redux-actions'
import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'
import { setProducersInfo } from 'store/actions/producersInfo'

const {
  fetchProducersByAreaStart,
  fetchProducersByAreaSuccess,
  fetchProducersByAreaError,
  resetProducersByArea
} = createActions({
  FETCH_PRODUCERS_BY_AREA_START: () => {},
  FETCH_PRODUCERS_BY_AREA_SUCCESS: data => ({ data }),
  FETCH_PRODUCERS_BY_AREA_ERROR: error => ({ error }),
  RESET_PRODUCERS_BY_AREA: () => {}
})

const fetchProducersByArea = (lat, lng, distance) => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchProducersByAreaStart())
    const maxCharAmount = 8
    const shorterLat = `${lat}`.substring(0, maxCharAmount)
    const shorterLng = `${lng}`.substring(0, maxCharAmount)
    const shorterDistance = parseInt(distance)
    try {
      const response = await api.get(
        `${API_ROUTES.PRODUCERS_BY_AREA}/${shorterLat}/${shorterLng}/${shorterDistance}`
      )
      if (!response) throw Error()
      dispatch(fetchProducersByAreaSuccess(response.data.productoras))
      dispatch(setProducersInfo(response.data.totalProductoras))
    } catch (error) {
      dispatch(fetchProducersByAreaError(error))
    }
  }
}

const resetAreaPoints = () => dispatch => dispatch(resetProducersByArea())

export {
  fetchProducersByArea,
  fetchProducersByAreaStart,
  fetchProducersByAreaSuccess,
  fetchProducersByAreaError,
  resetProducersByArea,
  resetAreaPoints
}
