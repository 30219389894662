import React, { Fragment } from 'react'
import PageBlock from 'components/PageBlock'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PageWrapper } from './styled'

const Page = ({ children, ...otherProps }) => (
  <Fragment>
    <PageWrapper {...otherProps}>
      <PageBlock {...otherProps}>{children}</PageBlock>
    </PageWrapper>
    <ToastContainer
      style={{
        minWidth: 600
      }}
      closeButton={false}
      position={toast.POSITION.BOTTOM_CENTER}
      autoClose={55000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable={false}
      pauseOnHover
      className={'toast'}
      bodyClassName={'toastBody'}
    />
  </Fragment>
)

export default Page
