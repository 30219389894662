import cordobaBoundaries from 'utils/provincesBoundaries/cordoba.json'
import buenosAiresBoundaries from 'utils/provincesBoundaries/buenosAires.json'
import rioNegro from 'utils/provincesBoundaries/rioNegro.json'
import entreRios from 'utils/provincesBoundaries/entreRios.json'
import laPampa from 'utils/provincesBoundaries/laPampa.json'
import sanLuis from 'utils/provincesBoundaries/sanLuis.json'
import santaFe from 'utils/provincesBoundaries/santaFe.json'

const auxFunc = (bounds, id) => {
  bounds.features[0].properties.id = id
  return bounds
}

export const reestructureProvinceData = provinces => {
  switch (provinces.Descripcion) {
    case 'Buenos Aires':
      return {
        name: 'Buenos Aires',
        id: provinces.IdItem,
        coordinates: { lat: -36.21938874753203, lng: -60.25095779101821 },
        boundries: auxFunc(buenosAiresBoundaries, provinces.IdItem)
      }
    case 'Córdoba':
      return {
        name: 'Córdoba',
        id: provinces.IdItem,
        coordinates: { lat: -31.414913, lng: -64.192615 },
        boundries: auxFunc(cordobaBoundaries, provinces.IdItem)
      }

    case 'Entre Ríos':
      return {
        name: 'Entre Ríos',
        id: provinces.IdItem,
        coordinates: { lat: -32.17143803891716, lng: -59.26986968749998 },
        boundries: auxFunc(entreRios, provinces.IdItem)
      }

    case 'La Pampa':
      return {
        name: 'La Pampa',
        id: provinces.IdItem,
        coordinates: { lat: -38.0833509741471, lng: -66.10858389365228 },
        boundries: auxFunc(laPampa, provinces.IdItem)
      }

    case 'Río Negro':
      return {
        name: 'Río Negro',
        id: provinces.IdItem,
        coordinates: { lat: -40.18125, lng: -67.18012 },
        boundries: auxFunc(rioNegro, provinces.IdItem)
      }

    case 'San Luís':
      return {
        name: 'San Luís',
        id: provinces.IdItem,
        coordinates: { lat: -33.49154712103139, lng: -66.34506499999998 },
        boundries: auxFunc(sanLuis, provinces.IdItem)
      }

    case 'Santa Fe':
      return {
        name: 'Santa Fe',
        id: provinces.IdItem,
        coordinates: { lat: -31.60247294058056, lng: -61.022448991030274 },
        boundries: auxFunc(santaFe, provinces.IdItem)
      }
  }
}
export default reestructureProvinceData
