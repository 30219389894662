import { createActions } from 'redux-actions'

const { drawerOpen, drawerClose, activeRadiusSet, activeRadiusRemove } = createActions({
  DRAWER_OPEN: () => {},
  DRAWER_CLOSE: () => {},
  ACTIVE_RADIUS_SET: radius => ({ radius }),
  ACTIVE_RADIUS_REMOVE: () => {}
})

const openDrawer = () => dispatch => dispatch(drawerOpen())

const closeDrawer = () => dispatch => dispatch(drawerClose())

const setActiveRadius = radius => dispatch => {
  const truncatedRadius = Math.round(radius * 100) / 100
  dispatch(activeRadiusSet(truncatedRadius))
}

const removeActiveRadius = () => dispatch => dispatch(activeRadiusRemove())

export {
  openDrawer,
  drawerOpen,
  closeDrawer,
  drawerClose,
  setActiveRadius,
  activeRadiusSet,
  removeActiveRadius,
  activeRadiusRemove
}
