import { handleActions } from 'redux-actions'
import { enableEditMode, disableEditMode } from 'store/actions/editMode'

const defaultState = {
  enabled: false
}

const reducer = handleActions(
  {
    [enableEditMode]: () => ({ enabled: true }),
    [disableEditMode]: () => ({ enabled: false })
  },
  defaultState
)

export default reducer
