import { handleActions } from 'redux-actions'
import {
  fetchProducersByLevelStart,
  fetchProducersByLevelSuccess,
  fetchProducersByLevelError,
  producersByLevelReset,
  setBaseProducersByLevel,
  producersByLevelRestart
} from 'store/actions/producersByLevel'

const defaultState = {
  values: { lvl1: [], lvl2: [], lvl3: [] },
  isFetching: false,
  error: null,
  baseValues: { lvl1: [], lvl2: [], lvl3: [] }
}

const updateValuesByLevel = (target, data) => ({
  ...target,
  [`lvl${data.level}`]: data.response.data
})

const reducer = handleActions(
  {
    [fetchProducersByLevelStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchProducersByLevelSuccess]: (state, { payload: { data } }) => ({
      ...state,
      isFetching: false,
      values: updateValuesByLevel(state.values, data)
    }),
    [fetchProducersByLevelError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [producersByLevelReset]: () => defaultState,

    [setBaseProducersByLevel]: (state, { payload: { data } }) => ({
      ...state,
      baseValues: updateValuesByLevel(state.baseValues, data)
    }),

    [producersByLevelRestart]: state => ({
      ...state,
      values: state.baseValues
    })
  },
  defaultState
)

export default reducer
