import React from 'react'
import i18n from 'services/i18n'
import { capitalizeFirstLetter, allToLower, getShorterString } from 'utils/strings'
import { ListItemText, ListItem, Tooltip } from '@material-ui/core'
import PulseLoader from 'react-spinners/PulseLoader'
import theme from 'config/theme'
import {
  Container,
  Wrapper,
  StyledButton,
  ButtonWrapper,
  Title,
  NoData,
  CustomCheckbox,
  StyledDivSpacer,
  ColorCircle
} from './styled'

class CheckList extends React.Component {
  state = {
    expanded: false,
    itemsToShow: 3
  }

  toggleExpandedState = () => this.setState({ expanded: !this.state.expanded })

  renderItemContent = (content, color) => {
    const maxCharAmount = color ? 12 : 14
    const contentData =
      content.length > maxCharAmount ? getShorterString(content, maxCharAmount) : content
    return capitalizeFirstLetter(allToLower(contentData))
  }

  getItemsToShow = () => (this.state.expanded ? this.props.values.length : this.state.itemsToShow)

  showSeeMoreButton = () => (this.props.values ? this.props.values.length : this.state.itemsToShow)

  handleItemSelected = item => {
    this.props.onItemSelected(item)
  }

  areValuesAvaiable = values => values && values.length > 0

  render() {
    const { values, title, isLoading, selected, disabled, business } = this.props
    return (
      <Container>
        {title && <Title>{title}</Title>}
        {isLoading ? (
          <NoData>
            <PulseLoader sizeUnit={'px'} size={10} color={theme.colors.mastelloneGreen} />
          </NoData>
        ) : (
          <Wrapper>
            {this.areValuesAvaiable(values) ? (
              values.slice(0, this.getItemsToShow()).map(item => (
                <Tooltip key={item.IdItem} title={item.Descripcion} placement="right">
                  <ListItem
                    key={item.IdItem}
                    value={item.IdItem}
                    style={{ paddingTop: '0px', paddingBottom: '0px' }}
                    onClick={() => {
                      if (!disabled) this.handleItemSelected(item)
                    }}
                    disabled={disabled}
                  >
                    <CustomCheckbox
                      style={{ padding: '2px 8px' }}
                      checked={selected[item.IdItem] ? true : false}
                      disabled={disabled}
                    />

                    {business && (
                      <ColorCircle color={item.ColorHex || theme.colors.lamborghiniDiabloPurple} />
                    )}
                    <ListItemText
                      primary={this.renderItemContent(item.Descripcion, item.ColorHex)}
                      disabled={disabled}
                    />
                  </ListItem>
                </Tooltip>
              ))
            ) : (
              <NoData>
                {capitalizeFirstLetter(`${i18n('CUSTOM_DRAWER').DRAWER_NO_DATA_TO_SHOW}`)}
              </NoData>
            )}
            {this.showSeeMoreButton() <= this.state.itemsToShow ||
            !this.areValuesAvaiable(values) ? (
              <StyledDivSpacer />
            ) : (
              <ButtonWrapper>
                <StyledButton onClick={!disabled && this.toggleExpandedState} disabled={disabled}>
                  {this.state.expanded
                    ? capitalizeFirstLetter(`${i18n('CUSTOM_DRAWER').DRAWER_SEE_LESS}`)
                    : capitalizeFirstLetter(`${i18n('CUSTOM_DRAWER').DRAWER_SEE_MORE}`)}
                </StyledButton>
              </ButtonWrapper>
            )}
          </Wrapper>
        )}
      </Container>
    )
  }
}

export default CheckList
