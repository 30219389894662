import { handleActions } from 'redux-actions'
import {
  fetchProducersByAreaStart,
  fetchProducersByAreaSuccess,
  fetchProducersByAreaError,
  resetProducersByArea
} from 'store/actions/producersByArea'

const defaultState = {
  values: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchProducersByAreaStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchProducersByAreaSuccess]: (state, { payload: { data } }) => ({
      ...state,
      isFetching: false,
      values: data
    }),
    [fetchProducersByAreaError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [resetProducersByArea]: () => defaultState
  },
  defaultState
)

export default reducer
