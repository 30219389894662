import { InputAdornment, withStyles } from '@material-ui/core'
import { RemoveRedEye } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextFieldMaterial from '../TextFieldMaterial'

const styles = () => ({
  eye: {
    cursor: 'pointer'
  }
})

class PasswordInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      passwordIsMasked: true
    }
  }

  togglePasswordMask = () => {
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked
    }))
  }

  render() {
    const { classes } = this.props
    const { passwordIsMasked } = this.state

    return (
      <TextFieldMaterial
        type={passwordIsMasked ? 'password' : 'text'}
        {...this.props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <RemoveRedEye className={classes.eye} onClick={this.togglePasswordMask} />
            </InputAdornment>
          )
        }}
      />
    )
  }
}

PasswordInput.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
}

export default withStyles(styles)(PasswordInput)
