import { handleActions } from 'redux-actions'
import { activeFilters, resetActiveFilters } from 'store/actions/filter'

const defaultState = {
  business: null,
  province: null,
  zone: null
}

const reducer = handleActions(
  {
    [activeFilters]: (state, { payload: formattedData }) => ({ ...formattedData.formattedData }),
    [resetActiveFilters]: () => defaultState
  },
  defaultState
)

export default reducer
