import React from 'react'
import styled from 'styled-components'

export const Button = styled.button`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.mastelloneGreen};
  outline: none;
  border: none;
  cursor: pointer;
`

const ButtonRounded = ({ onClick, backgroundColor, children }) => (
  <Button onClick={onClick} backgroundColor={backgroundColor}>
    {children}
  </Button>
)

export default ButtonRounded
