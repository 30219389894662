import styled from 'styled-components'

export const StyledTitle = styled.text`
  padding: 0px 16px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
`

export const StyledLoaderWrapper = styled.div`
  margin: 10px 0px;
  padding-left: 11px;
`
