import { createActions } from 'redux-actions'

const { selectedProvinceToDelete, resetProvinceToDelete } = createActions({
  RESET_PROVINCE_TO_DELETE: () => {},
  SELECTED_PROVINCE_TO_DELETE: data => ({ data })
})

const setSelectedProvinceToDelete = status => dispatch =>
  dispatch(selectedProvinceToDelete({ status }))

const resetSelectdProvinceToDelete = () => dispatch => dispatch(resetProvinceToDelete())

export {
  setSelectedProvinceToDelete,
  selectedProvinceToDelete,
  resetSelectdProvinceToDelete,
  resetProvinceToDelete
}
