import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const LoaderWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  height: -webkit-fill-available;
`

export const LoaderIconWrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`

export const EditWrapper = styled.div`
  height: 100%;
  width: 100%;
  border: ${({ edit }) => (edit ? 3 : 0)}px solid red;

  & > div {
    height: ${({ edit }) => (edit ? 99.7 : 100)}% !important;
    width: ${({ edit }) => (edit ? 99.5 : 100)}% !important;
  }
`
