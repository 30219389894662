import React from 'react'
import { getDate } from 'utils/dateParser'
import Table from 'react-bootstrap/Table'
import { MainDiv, Container, CloseSapn, ImgDiv, Img, SpanDate, BodyDiv } from './styled'
export default class ChartModal extends React.Component {
  onClose = () => {
    this.props.show = false
  }

  render() {
    const { data, loading } = this.props
    if (!this.props.show) {
      return null
    }
    return loading || data.length == 0 ? (
      <Container>
        <MainDiv>
          <div>
            <CloseSapn onClick={this.props.closeModal}>X</CloseSapn>
          </div>
          <BodyDiv>
            {loading ? (
              <span> Porfavor aguarde un instante.</span>
            ) : (
              <span> Ocurrio un error al conectar , porfavor vuelva a intentarlo.</span>
            )}
          </BodyDiv>
        </MainDiv>
      </Container>
    ) : (
      <Container>
        <MainDiv>
          <div>
            <CloseSapn onClick={this.props.closeModal}>X</CloseSapn>
          </div>
          <BodyDiv>
            <ImgDiv>
              <Img width="70%" src={`data:image/jpeg;base64,${data.imagen}`} />
            </ImgDiv>
            <SpanDate>
              <span>{getDate(data.Fecha)}</span>
            </SpanDate>
            <Table striped bordered hover className={'m-lg-auto w-75'}>
              <thead>
                <tr>
                  <th>Posicion</th>
                  <th>Nombre</th>
                  <th>Capacidad de Secado</th>
                  <th>Procentaje de Acumulacion</th>
                </tr>
              </thead>
              <tbody>
                {data.listaDatos.map(data => (
                  <tr key={data.Nombre}>
                    <td>{data.posicion}</td>
                    <td>{data.Nombre}</td>
                    <td>{data.CapacidadSecado}</td>
                    <td>{data.PorcentajeAcumulacion}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </BodyDiv>
        </MainDiv>
      </Container>
    )
  }
}
