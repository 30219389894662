import React, { Component, Fragment } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import theme from 'config/theme'
import { capitalizeFirstLetter, allToLower, allToCaps } from 'utils/strings'
import i18n from 'services/i18n'
import Accordion from 'react-bootstrap/Accordion'
import 'bootstrap/dist/css/bootstrap.min.css'
import API_ROUTES from 'config/apiRoutes'
import {
  StyledTitle,
  StyledLore,
  StyledSubTitle,
  StyledDivWrapper,
  StyledDivWrapperData,
  StyledDivWrapperExpand,
  StyledButtonExpand,
  StyledDivWrapperObs,
  StyleBusinessDiv,
  DividerObsStyles,
  StyledDivWrapperItems,
  StyledBoldLore,
  StyledDivWrapperInfo,
  StyledDivAccordion,
  DividerLine
} from './styled'

import ArrowRight from '@material-ui/icons/KeyboardArrowRight'
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import { Divider } from '@material-ui/core'

import { getDate, getShortDate } from 'utils/dateParser'

class CustomInfoWindow extends Component {
  constructor(props) {
    super(props)
    if (!props.listenerSetted) {
      this.setButtonEvent('expand_btn', 'click', () => {
        this.props.onHandleExpand(this.props.selectedPlace.idUnidadProductora)
      })
    }
  }

  setButtonEvent = (targetId, event, resource, delay = 100) =>
    setTimeout(() => {
      const button = document.getElementById(targetId)
      if (button) {
        let btnClone = button.cloneNode(true)
        button.parentNode.replaceChild(btnClone, button)
        btnClone.addEventListener(event, () => resource())
      }
    }, delay)

  selectedPlaceType = selectedPlace =>
    selectedPlace.coordinates ? selectedPlace.coordinates : selectedPlace.position

  selectedPlaceCoordinatesType = (selectedPlace, isLat) => {
    const place = this.convertToDMS(this.selectedPlaceType(selectedPlace))

    return isLat ? `${place.lat}`.substring(0, 11) : `${place.lng}`.substring(0, 11)
  }

  toDegreesMinutesAndSeconds = coordinate => {
    var absolute = Math.abs(coordinate)
    var degrees = Math.floor(absolute)
    var minutesNotTruncated = (absolute - degrees) * 60
    var minutes = Math.floor(minutesNotTruncated)
    var seconds = Math.floor((minutesNotTruncated - minutes) * 60)
    return `${degrees}°${minutes}'${seconds}"`
  }

  convertToDMS = place => {
    var latitude = this.toDegreesMinutesAndSeconds(place.lat)
    var longitude = this.toDegreesMinutesAndSeconds(place.lng)
    return { lat: latitude, lng: longitude }
  }

  thereAreValues = producerData => producerData.values

  displayError = () => capitalizeFirstLetter(`${i18n('INFO_WINDOW').WINDOW_EDIT_ERROR}`)

  DisplaySimple = selectedPlace => {
    return (
      <Fragment>
        <StyledTitle>{selectedPlace.name}</StyledTitle>
        <StyledLore>
          {capitalizeFirstLetter(`${i18n('INFO_WINDOW').WINDOW_EDIT_LAT}`)}:{' '}
          {this.selectedPlaceType(selectedPlace) &&
            this.selectedPlaceCoordinatesType(selectedPlace, true)}
          <br />
          {capitalizeFirstLetter(`${i18n('INFO_WINDOW').WINDOW_EDIT_LNG}`)}:{' '}
          {this.selectedPlaceType(selectedPlace) &&
            this.selectedPlaceCoordinatesType(selectedPlace, false)}
        </StyledLore>
      </Fragment>
    )
  }

  renderLoader = () => (
    <PulseLoader sizeUnit={'px'} size={10} color={theme.colors.mastelloneGreen} />
  )

  renderObservations = obs => {
    return (
      <Fragment>
        <Accordion.Item id={'observacion_id'} eventKey="observacion">
          <Accordion.Header>
            {capitalizeFirstLetter(`${i18n('INFO_WINDOW').WINDOW_EDIT_OBS}`)}
          </Accordion.Header>
          <Accordion.Body>
            {obs.map((ob, index) => (
              <StyledDivWrapperItems key={index}>
                <StyledSubTitle>
                  {getDate(ob.Fecha)}
                  <StyledBoldLore>{` (${allToCaps(ob.Usuario)})`}</StyledBoldLore>:
                </StyledSubTitle>
                <StyledLore>{capitalizeFirstLetter(allToLower(ob.Observacion))}</StyledLore>
                <Divider style={DividerObsStyles(obs.length === index + 1 ? 'none' : 'inherit')} />
              </StyledDivWrapperItems>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Fragment>
    )
  }

  renderBusinessAdditional = data => {
    return (
      <Fragment>
        <StyleBusinessDiv>
          <div>
            <StyledLore>{'Razon Social: ' + data.RazonSocial}</StyledLore>
            <StyledBoldLore>{'cuit: ' + data.Cuit}</StyledBoldLore>
            <StyledLore>{'Codigo Postal: ' + data.CodigoPostal}</StyledLore>
            <StyledLore>{'Domicilio: ' + data.Domicilio}</StyledLore>
            <StyledLore>{data.Habilitado ? 'Habilitado: Si' : 'Habilitado: No'}</StyledLore>
            <StyledLore>{'Ranking: ' + data.Ranking}</StyledLore>
            <StyledLore>{'Nivel: ' + data.Nivel}</StyledLore>

            <br />
            <StyledBoldLore>Productividad</StyledBoldLore>
            <DividerLine></DividerLine>
            <StyledLore>
              {data.LitrosDia != null ? 'Litros por dia: ' + data.LitrosDia : 'Litros por dia: -'}
            </StyledLore>
            <StyledLore>
              {data.Colaboradores != null
                ? 'Colaboradores: ' + data.Colaboradores
                : 'Colaboradores: -'}
            </StyledLore>

            <br />
            <StyledBoldLore>Exportación</StyledBoldLore>
            <DividerLine></DividerLine>
            <StyledLore>
              {data.DestinosExportacion != null
                ? 'Destinos Exportacion: ' + data.DestinosExportacion
                : 'Destinos Exportacion: -'}
            </StyledLore>
            <StyledLore>
              {data.ProductosExportacion != null
                ? 'ProductosExportacion: ' + data.ProductosExportacion
                : 'ProductosExportacion : -'}
            </StyledLore>
            <br />
            <StyledBoldLore>Marcas</StyledBoldLore>
            <DividerLine></DividerLine>
            <StyledDivWrapperItems>
              <iframe
                width="100%"
                src={`https://docs.google.com/viewer?url=${API_ROUTES.BASE_URL +
                  data.Logo}&embedded=true`}
              ></iframe>
            </StyledDivWrapperItems>
            <br />
            <StyledBoldLore>Documento</StyledBoldLore>
            <DividerLine></DividerLine>
            <StyledDivWrapperItems>
              <a href={API_ROUTES.BASE_URL + data.Documento}>{data.Documento}</a>
              <StyledLore>
                {data.Extension != null ? 'Extension : ' + data.Extension : 'Extension : -'}
              </StyledLore>
              <StyledLore>
                {data.Denominacion != null
                  ? 'Denominacion: ' + data.Denominacion
                  : 'Denominacion: -'}
              </StyledLore>
              <StyledLore>
                {data.Fecha != null ? 'Fecha : ' + getDate(data.Fecha) : 'Fecha: -'}
              </StyledLore>
            </StyledDivWrapperItems>
          </div>
        </StyleBusinessDiv>
      </Fragment>
    )
  }

  render() {
    const {
      selectedPlace,
      producerData,
      expandStatus,
      isFetchingObs,
      observations,
      errorObs,
      isFetchingBusAdd,
      businessAdditional,
      errorBusAdd,
      accordionEventValue
    } = this.props

    return producerData.isFetching ? (
      this.renderLoader()
    ) : producerData.error ? (
      this.displayError()
    ) : producerData.values ? (
      <StyledDivWrapper>
        <StyledDivWrapperData isExpanded={expandStatus}>
          <StyledDivWrapper>
            <StyledDivWrapperInfo>
              <div>
                <div>
                  <StyledTitle style={{ overflowWrap: 'break-word', maxWidth: '170px' }}>
                    {selectedPlace.name}
                  </StyledTitle>
                  <br />
                  <StyledLore>
                    {capitalizeFirstLetter(`${i18n('INFO_WINDOW').WINDOW_EDIT_LOCALIZATION}`)}:{' '}
                    {this.thereAreValues(producerData) ? producerData.values.Provincia : ''},
                    {this.thereAreValues(producerData)
                      ? capitalizeFirstLetter(allToLower(producerData.values.Zona))
                      : ''}
                  </StyledLore>
                  <StyledLore>
                    {capitalizeFirstLetter(`${i18n('INFO_WINDOW').WINDOW_EDIT_LAT}`)}:{' '}
                    {this.selectedPlaceType(selectedPlace) &&
                      this.selectedPlaceCoordinatesType(selectedPlace, true)}
                    <br />
                    {capitalizeFirstLetter(`${i18n('INFO_WINDOW').WINDOW_EDIT_LNG}`)}:{' '}
                    {this.selectedPlaceType(selectedPlace) &&
                      this.selectedPlaceCoordinatesType(selectedPlace, false)}
                  </StyledLore>
                  <StyledLore>
                    {capitalizeFirstLetter(`${i18n('INFO_WINDOW').WINDOW_EDIT_KM}`)}:{' '}
                    {this.thereAreValues(producerData) ? producerData.values.KmDeTierra : ''}
                  </StyledLore>

                  <StyledLore>
                    {capitalizeFirstLetter(`${i18n('INFO_WINDOW').WINDOW_EDIT_LITERS}`)}:{' '}
                    {selectedPlace.liters}
                  </StyledLore>
                  {selectedPlace.isTambo && (
                    <StyledLore>
                      {capitalizeFirstLetter(`${i18n('INFO_WINDOW').WINDOW_EDIT_PRICE}`)}:{' '}
                      {selectedPlace.price}
                    </StyledLore>
                  )}
                  <StyledLore>
                    {capitalizeFirstLetter(`${i18n('INFO_WINDOW').REFERENCE_DATE}`)}:{' '}
                    {getShortDate(producerData.values.FechaReferencia)}
                  </StyledLore>
                </div>

                <div>
                  {expandStatus && (
                    <Fragment>
                      <StyledDivWrapperItems>
                        <br />
                        <br />
                        <StyledBoldLore>Secado</StyledBoldLore>
                        <DividerLine></DividerLine>
                        <StyledLore>
                          {businessAdditional.CapacidadSecado != null
                            ? 'Capacidad Secado: ' + businessAdditional.CapacidadSecado
                            : 'Capacidad Secado: -'}
                        </StyledLore>
                        <StyledLore>
                          {businessAdditional.AcumulacionSecado != null
                            ? 'Acumulacion Secado: ' + businessAdditional.AcumulacionSecado
                            : 'Acumulacion Secado: -'}
                        </StyledLore>
                      </StyledDivWrapperItems>

                      {businessAdditional.CapacidadIndustria && (
                        <StyledDivWrapperItems>
                          <br />
                          <StyledBoldLore>Capacidad</StyledBoldLore>
                          <DividerLine></DividerLine>(
                          <StyledLore>
                            {'Capacidad: ' + businessAdditional.CapacidadIndustria}
                          </StyledLore>
                        </StyledDivWrapperItems>
                      )}

                      {businessAdditional.ProductosIndustria && (
                        <StyledDivWrapperItems>
                          <br />
                          <StyledBoldLore>Productos</StyledBoldLore>
                          <DividerLine></DividerLine>
                          <StyledLore>{businessAdditional.ProductosIndustria}</StyledLore>
                        </StyledDivWrapperItems>
                      )}

                      {businessAdditional.OtrosIndustria && (
                        <StyledDivWrapperItems>
                          <br />
                          <StyledBoldLore>Otros</StyledBoldLore>
                          <DividerLine></DividerLine>
                          <StyledLore>{businessAdditional.OtrosIndustria}</StyledLore>
                        </StyledDivWrapperItems>
                      )}
                    </Fragment>
                  )}
                </div>
              </div>
            </StyledDivWrapperInfo>
            {expandStatus ? (
              <StyledDivAccordion>
                <Accordion defaultActiveKey={accordionEventValue} flush>
                  {!selectedPlace.isTambo ? (
                    <StyledDivWrapperObs
                      expandStatus={expandStatus}
                      isFetchingBusAdd={isFetchingBusAdd}
                    >
                      {isFetchingBusAdd ? (
                        this.renderLoader()
                      ) : businessAdditional != null ? (
                        this.renderBusinessAdditional(businessAdditional)
                      ) : (
                        <StyledTitle>
                          {errorBusAdd
                            ? this.displayError()
                            : capitalizeFirstLetter(`${i18n('INFO_WINDOW').WINDOW_EDIT_NO_OBS}`)}
                        </StyledTitle>
                      )}
                    </StyledDivWrapperObs>
                  ) : null}
                  <StyledDivWrapperObs expandStatus={expandStatus} isFetchingObs={isFetchingObs}>
                    {isFetchingObs ? (
                      this.renderLoader()
                    ) : observations.length ? (
                      this.renderObservations(observations)
                    ) : (
                      <StyledTitle>
                        {errorObs
                          ? this.displayError()
                          : capitalizeFirstLetter(`${i18n('INFO_WINDOW').WINDOW_EDIT_NO_OBS}`)}
                      </StyledTitle>
                    )}
                  </StyledDivWrapperObs>
                </Accordion>
              </StyledDivAccordion>
            ) : null}
          </StyledDivWrapper>
        </StyledDivWrapperData>
        <StyledDivWrapperExpand>
          <StyledButtonExpand id={'expand_btn'}>
            {expandStatus ? <ArrowLeft /> : <ArrowRight />}
          </StyledButtonExpand>
        </StyledDivWrapperExpand>
      </StyledDivWrapper>
    ) : (
      this.DisplaySimple(selectedPlace)
    )
  }
}

export default CustomInfoWindow
