import React from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PulseLoader from 'react-spinners/PulseLoader'
import theme from 'config/theme'
import { StyledTitle, StyledLoaderWrapper } from './styled'

export const ExpansionPanelDetailsStyles = () => ({
  padding: '0'
})

const StyledExpansionPanelSummary = withStyles({
  root: {
    padding: '0px 12px',
    minHeight: '35px'
  },
  content: {
    margin: '0px'
  }
})(ExpansionPanelSummary)

const checkBoxStyles = {
  root: {
    '&$checked': {
      color: '#19975d'
    }
  },
  checked: {}
}

export const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox)

const ExpandableElement = ({
  title,
  withCheck,
  isLoading,
  id,
  handleSelectorChange,
  children,
  disabled,
  checked,
  ...otherProps
}) => (
  <ExpansionPanel
    {...otherProps}
    style={{
      boxShadow: 'none',
      borderTop: otherProps.expanded ? '1px solid rgba(0, 0, 0, 0.12)' : 'none'
    }}
  >
    <StyledExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
      {isLoading ? (
        <StyledLoaderWrapper>
          <PulseLoader sizeUnit={'px'} size={10} color={theme.colors.mastelloneGreen} />
        </StyledLoaderWrapper>
      ) : (
        <div>
          {withCheck ? (
            <CustomCheckbox
              onChange={() => handleSelectorChange(id)}
              style={{ padding: '2px 8px' }}
              checked={checked}
              disabled={disabled}
            />
          ) : (
            ''
          )}
          <StyledTitle>{title}</StyledTitle>
        </div>
      )}
    </StyledExpansionPanelSummary>
    <ExpansionPanelDetails style={ExpansionPanelDetailsStyles()}>{children}</ExpansionPanelDetails>
  </ExpansionPanel>
)

export default ExpandableElement
