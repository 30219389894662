import React from 'react'
import { Marker } from 'google-maps-react'
import { enterprise, emptyBottle, halfBottle, fullBottle } from 'utils/images'

const minimumLiters = 5000

const midLiters = 10000

const getScale = draggable =>
  !draggable
    ? { width: window.innerHeight * 0.046, height: window.innerHeight * 0.045 }
    : { width: window.innerHeight * 0.046, height: window.innerHeight * 0.058 }

const getIcon = (point, draggable) =>
  point.isTambo
    ? renderTambo(point.liters, point.color, draggable)
    : renderEnterprise(point.color, draggable)

const renderTambo = (liters, color, draggable) => {
  if (liters <= minimumLiters) {
    return {
      url: emptyBottle(color),
      scaledSize: getScale(draggable)
    }
  } else if (liters > minimumLiters && liters <= midLiters) {
    return {
      url: halfBottle(color),
      scaledSize: getScale(draggable)
    }
  } else {
    return {
      url: fullBottle(color),
      scaledSize: getScale(draggable)
    }
  }
}

const renderEnterprise = (color, draggable) => ({
  url: enterprise(color),
  scaledSize: getScale(draggable)
})

const CustomMarker = ({ point, draggable, ...otherProps }) => (
  <Marker
    position={point.coordinates}
    name={point.name}
    icon={getIcon(point, draggable)}
    draggable={draggable}
    {...otherProps}
  />
)

export default CustomMarker
