import React, { Component } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { removeLocalStatus } from 'utils/session'
import theme from 'config/theme'
import {
  Container,
  FormContainer,
  FormWrapper,
  ImageWrapper,
  StyledImg,
  ButtonWrapper,
  ErrorLabel
} from './styled'
import Logo from 'assets/imgs/logo.png'

import PasswordInput from 'components/TextFieldMaterialPassword'
import TextFieldMaterial from 'components/TextFieldMaterial'
import ButtonMaterial from 'components/ButtonMaterial'
import { login } from 'store/actions/authentication'

import i18n from 'services/i18n'
import { capitalizeFirstLetter, allToCaps } from 'utils/strings'

class Login extends Component {
  renderForm = () => {
    const SignUpSchema = Yup.object().shape({
      user: Yup.string().required(
        capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_FORM_USERNAME_ERROR}`)
      ),
      password: Yup.string().required(
        capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_FORM_PASSWORD_ERROR}`)
      )
    })
    return (
      <Formik
        initialValues={{
          user: '',
          password: ''
        }}
        validateOnChange={false}
        validationSchema={SignUpSchema}
        onSubmit={this.handleSubmit}
        render={e => this.getFormContent(e)}
      />
    )
  }

  handleSubmit = async ({ user, password }) => {
    removeLocalStatus()
    await this.props.login(user, password)
    if (!this.props.authReducer.error) {
      this.props.history.push(`/`)
    }
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <FormWrapper>
        <TextFieldMaterial
          label={allToCaps(`${i18n('LOGIN').LOGIN_FORM_INPUT_USERNAME}`)}
          helperText={errors.user}
          margin="normal"
          value={values.user}
          error={errors.user}
          onChange={({ target: value }) => setFieldValue('user', value.value)}
        />

        <PasswordInput
          label={allToCaps(`${i18n('LOGIN').LOGIN_FORM_INPUT_PASSWORD}`)}
          helperText={errors.password}
          value={values.password}
          error={errors.password}
          onChange={({ target: value }) => setFieldValue('password', value.value)}
        />
        <ButtonWrapper margin={30}>
          <ButtonMaterial
            type={'submit'}
            background={theme.colors.red}
            color={theme.colors.white}
            borderRadius={'30'}
            fontSize={'18'}
            height={'45'}
            loading={this.props.authReducer.isFetching}
          >
            {allToCaps(`${i18n('LOGIN').LOGIN_FORM_BUTTON}`)}
          </ButtonMaterial>
        </ButtonWrapper>
        {this.props.authReducer.error && (
          <ErrorLabel>{capitalizeFirstLetter(`${i18n('LOGIN').LOGIN_ERROR_MESSAGE}`)}</ErrorLabel>
        )}
      </FormWrapper>
    </FormContainer>
  )

  render() {
    return (
      <Container>
        <ImageWrapper>
          <StyledImg src={Logo} />
        </ImageWrapper>
        {this.renderForm()}
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    authReducer: state.auth
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))
