import React from 'react'
import Table, { HeaderCell, TableCell } from 'components/Table'
import mediaQueries from 'config/media-queries'
import styled from 'styled-components'
import i18n from 'services/i18n'
import { capitalizeFirstLetter, allToCaps } from 'utils/strings'

const Container = styled.div`
  position: absolute;
  width: 350px;
  background: white;
  bottom: 30px;
  left: 0;
  border-radius: 4px;

  ${mediaQueries.laptop`
    width: 310px;
    left: 30px;
  `}

  ${mediaQueries.mobile`
    display: none;
  `}
`

const UpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

class ProducersTable extends React.Component {
  generateColumns = () => {
    const up = {
      accessor: 'up',
      Header: () => (
        <HeaderCell>
          <UpContainer>
            <label>{allToCaps(`${i18n('TABLE').TABLE_PU}`)}</label>
            <label>{capitalizeFirstLetter(`${i18n('TABLE').TABLE_TAMBO}`)}</label>
          </UpContainer>
        </HeaderCell>
      ),
      Cell: ({ value }) => <TableCell>{value}</TableCell>
    }
    const amount = {
      accessor: 'amount',
      Header: () => (
        <HeaderCell>{capitalizeFirstLetter(`${i18n('TABLE').TABLE_CUANTITY}`)}</HeaderCell>
      ),
      Cell: ({ value }) => <TableCell>{value}</TableCell>
    }
    const capacity = {
      accessor: 'capacity',
      Header: () => (
        <HeaderCell>
          <UpContainer>
            <label>{capitalizeFirstLetter(`${i18n('TABLE').TABLE_CAPACITY}`)}</label>
            <label>{i18n('TABLE').TABLE_1000}</label>
          </UpContainer>
        </HeaderCell>
      ),
      Cell: data => <TableCell>{this.getLtsQuantity(data.original)}</TableCell>
    }
    const averagePrice = {
      // accessor: 'averagePrice',
      Header: () => (
        <HeaderCell>{capitalizeFirstLetter(`${i18n('TABLE').LITERS_AVERAGE}`)}</HeaderCell>
      ),
      Cell: data => (
        <TableCell>{this.getAverageLiters(data.original.capacity, data.original.amount)}</TableCell>
      )
    }

    return [up, amount, capacity, averagePrice]
  }

  getLtsQuantity = data => {
    return this.formatNumber(data.capacity.toFixed(0))
  }

  getAverageLiters = (capacity, amount) => {
    if (capacity && amount) {
      const average = (capacity * 1000) / amount

      return this.formatNumber(average.toFixed(0))
    } else {
      return 0
    }
  }

  formatNumber = num => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }

  render() {
    const { tambosMhsa, tambosCompetencia } = this.props.info
    const tambosMH = {
      up: allToCaps(`${i18n('TABLE').TABLE_MASTELLONE}`),
      amount: tambosMhsa.cantidad,
      capacity: tambosMhsa.capacidad,
      averagePrice: tambosMhsa.precioPromedio
    }

    const tambosCompetence = {
      up: allToCaps(`${i18n('TABLE').TABLE_OTHERS}`),
      amount: tambosCompetencia.cantidad,
      capacity: tambosCompetencia.capacidad,
      averagePrice: tambosCompetencia.precioPromedio
    }

    return (
      <Container>
        <Table
          data={[tambosMH, tambosCompetence]}
          columns={this.generateColumns()}
          defaultPageSize={4}
        />
      </Container>
    )
  }
}

export default ProducersTable
