import React, { Component } from 'react'
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react'
import { googleMapApiKey } from './googleApiKey'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import CustomInfoWindow from 'components/CustomInfoWindow'
import theme from 'config/theme'
import drawingManagerConfig from 'config/drawingManager'
import SyncLoader from 'react-spinners/SyncLoader'
import { Container, LoaderWrapper, LoaderIconWrapper, EditWrapper } from './styled'
import { reestructureProvinceData } from 'utils/provinces'
import filterKeys from 'constants/filterKeys'
import {
  fetchProducersByProducerId,
  resetProducersByProducerId
} from 'store/actions/producersByProducerId'
import { setEditViewStatus } from 'store/actions/editView'
import { resetSelectedProvinces } from 'store/actions/selectedProvince'
import { setActiveFiltersFromMap } from 'store/actions/filter'
import { resetProducersByIds } from 'store/actions/producersByIds'
import { setMapStatus } from 'store/actions/mapStatus'
import { resetSelectdProvinceToDelete } from 'store/actions/provinceToDelete'
import { deleteSelectedProvince, reset } from 'store/actions/multipleFilter'
import { resetPoints } from 'store/actions/points'
import { resetFilters } from 'store/actions/filter'
import { resetProducersInfo } from 'store/actions/producersInfo'
import { fetchProducersByArea } from 'store/actions/producersByArea'
import { setSelectedProvinceToDelete } from 'store/actions/provinceToDelete'
import { deleteProducersByProvinceId } from 'store/actions/producersByProvinceId'
import { resetAreaPoints } from 'store/actions/producersByArea'
import { fetchProducersByProvinceId } from 'store/actions/producersByProvinceId'
import { fetchProducersByBusinessId } from 'store/actions/producersByBusinessId'
import { fetchProducersByZoneId } from 'store/actions/producersByZoneId'
import { modifyProducersCoordinates } from 'store/actions/modifyProducerCoordinates'
import {
  setBusinessPoints,
  setProvincePoints,
  setZonePoints,
  setDryingPoints
} from 'store/actions/points'
import { fetchObservations } from 'store/actions/observations'
import { fetchBusinessAdditional } from 'store/actions/businessAdditional'
import CustomMarker from 'components/CustomMarker'
import { isMultipleFilterEnabled, multiFilterKeyAmount } from 'utils/objects'
import { setActiveRadius, removeActiveRadius } from 'store/actions/ui'
import PopUp from 'components/PopUp'
import { capitalizeFirstLetter } from 'utils/strings'
import i18n from 'services/i18n'
import { resetSelectdRecordedQueriesById } from 'store/actions/recordedQueries'
import { userCanEdit, userCanEditIndustry } from 'utils/session'
export class MapContainer extends Component {
  constructor(props) {
    super(props)
  }
  state = {
    selectedShape: null,
    isExpanded: false,
    confirmationPopUp: false,
    accordionEventValue: ''
  }

  componentDidMount() {
    this.props.setEditViewStatus({ open: false })
  }

  componentDidUpdate = prevProps => {
    if (
      this.props.selectedProvince.id &&
      prevProps.selectedProvince.id !== this.props.selectedProvince.id
    ) {
      this.renderSelectedProvince(this.props.selectedProvince, this._map.map)
    }
    if (
      this.props.selectedProvinceToDelete.id &&
      prevProps.selectedProvinceToDelete.id !== this.props.selectedProvinceToDelete.id
    ) {
      this.deleteProvinceHighlight(this._map, this.props.selectedProvinceToDelete)
    }
    if (
      multiFilterKeyAmount(this.props.multipleFilter) === 0 &&
      multiFilterKeyAmount(prevProps.multipleFilter) > 0
    ) {
      this.removeAllProvinces(this._map)
    }
    if (isMultipleFilterEnabled(this.props.multipleFilter) && this.state.selectedShape) {
      this.resetShape(this._map.props, this._map.map)
    }
  }

  deleteProvinceHighlight = (map, item) => {
    map.map.data.forEach(function(feature) {
      if (feature.getProperty('id') === item.id) {
        map.map.data.remove(feature)
      }
    })
    this.props.resetSelectedProvinces()
    this.props.resetSelectdProvinceToDelete()
  }

  removeAllProvinces = map => {
    map.map.data.forEach(function(feature) {
      map.map.data.remove(feature)
    })
    this.props.resetSelectedProvinces()
    this.props.resetSelectdProvinceToDelete()
  }

  onMapClick = (mapProps, map, clickEvent) => {
    this.onMarkSelectedPosition(this.reestructureData(clickEvent.latLng))
    if (this.state.selectedShape) {
      this.resetShape(mapProps, map)
      this.props.removeActiveRadius()
      this.props.setMapStatus({
        showingInfoWindow: false,
        selectedPlace: {}
      })
    }
  }

  resetShape = async (mapProps, map) => {
    this.state.selectedShape.setMap(null)
    this.props.resetAreaPoints()
    this.props.resetProducersInfo()
    await this.setState({ selectedShape: null })
    this.initMap(mapProps, map)
  }

  reestructureData = latLng => {
    var lat = latLng.lat()
    var long = latLng.lng()
    const latLong = { coordinates: { lat: lat, lng: long }, name: 'Posición seleccionada' }
    return latLong
  }

  reestructureProducersData = data => {
    const reestructuredData = {
      coordinates: { lat: data.Latitud, lng: data.Longitud },
      name: data.Nombre,
      price: data.Precio,
      isTambo: data.EsTambo,
      idUnidadProductora: data.IdUnidadProductora,
      isMhsa: data.EsMHSA,
      liters: data.Litros,
      color: data.ColorHex || theme.colors.lamborghiniDiabloPurble
    }

    return reestructuredData
  }

  onMarkerClick = async (props, marker, point) => {
    if (point) {
      let centerDelta = 1.3
      point.coordinates.lat = parseFloat(point.coordinates.lat) + centerDelta
    }

    await this.props.setMapStatus({
      selectedPlace: point ? point : props,
      activeMarker: marker,
      showingInfoWindow: true,
      mapZoom: 7.9,
      center: point ? point.coordinates : props.position
    })
    this.setState({ isExpanded: false })
    if (point && this.props.mapStatus.showingInfoWindow) {
      this.props.fetchProducersByProducerId(this.props.mapStatus.selectedPlace.idUnidadProductora)
    } else {
      this.props.resetProducersByProducerId()
    }

    // hack to force zoom to update
    this.props.setMapStatus({
      mapZoom: 8
    })
  }

  onMarkSelectedPosition = latLng =>
    this.props.setMapStatus({ selectedLocation: latLng, center: latLng })

  onClose = () => {
    if (this.props.mapStatus.showingInfoWindow) {
      this.props.setMapStatus({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  }

  onHandleEditView = () => {
    this.props.setEditViewStatus(this.props.editView ? { open: false } : { open: true })
  }

  onHandleExpand = id => {
    const isExpanded = this.state.isExpanded ? false : true
    this.setState({ isExpanded })
    if (isExpanded) {
      this.props.fetchObservations(id)
      if (!this.props.mapStatus.selectedPlace.isTambo) {
        this.props.fetchBusinessAdditional(id)
      }
    }
  }

  accordionEvent = eventValue => {
    if (this.state.accordionEventValue != eventValue) {
      this.setState({ accordionEventValue: eventValue })
      this.props.setMapStatus({})
    }
  }

  initMap = (mapProps, map) => {
    const { google } = mapProps
    const { onShapeCreated } = this
    const { selectedShape } = this.state
    const drawingManager = new google.maps.drawing.DrawingManager(
      drawingManagerConfig(selectedShape, google, theme, map)
    )

    google.maps.event.addListener(drawingManager, 'overlaycomplete', event =>
      onShapeCreated(google, event, drawingManager)
    )
  }

  renderSelectedProvince = (selectedProvince, map) => {
    map.data.addGeoJson(selectedProvince.boundries)
    map.data.setStyle({ fillColor: 'green' })
  }

  onShapeCreated = (google, event, drawingManager) => {
    if (event.type === google.maps.drawing.OverlayType.CIRCLE) {
      const circle = event.overlay
      circle.type = event.type

      let radius = circle.getRadius()

      const center = circle.getCenter()
      drawingManager.setDrawingMode(null)
      drawingManager.setOptions({
        drawingControl: false
      })

      const centerPos = { lat: center.lat(), lng: center.lng() }

      this.props.setMapStatus({
        ...this.props.mapStatus,
        center: centerPos
      })

      this.resetAllFilters()

      this.setSelection(circle)
      this.props.fetchProducersByArea(centerPos.lat, centerPos.lng, radius)
      this.props.setActiveRadius(radius / 1000)
    }
  }

  fetchPointsByArea = circle => {
    const center = circle.getCenter()
    let radius = circle.getRadius()
    const centerPos = { lat: center.lat(), lng: center.lng() }
    this.props.fetchProducersByArea(centerPos.lat, centerPos.lng, radius)
  }

  // TODO --> Refactor this duplicated interaction to the Map Container

  resetAllFilters = () => {
    this.props.resetSelectdRecordedQueriesById()
    this.props.resetPoints()
    this.props.resetFilters()
    this.props.reset()
    this.props.resetProducersInfo()
    this.props.resetProducersByIds()
    this.checkProvinceDelete()
    this.props.setMapStatus({
      showingInfoWindow: false,
      selectedPlace: {}
    })
  }

  checkProvinceDelete = () => {
    if (
      this.props.selectedProvince.id !== '' &&
      this.props.filteredKeyActive !== filterKeys.PROVINCE
    ) {
      this.deleteProvince(this.props.selectedProvince)
    }
  }

  deleteProvince = province => {
    if (!province.IdItem) {
      province = { IdItem: province.id, Descripcion: province.name }
    }
    const reestructuredItem = reestructureProvinceData(province)
    this.props.deleteSelectedProvince(province)
    this.props.setSelectedProvinceToDelete(reestructuredItem)
    this.props.deleteProducersByProvinceId(province)
  }

  setSelection = shape => {
    this.setState({ selectedShape: shape })
  }

  getPoints = () => this.props.points

  handleFetchPointsByResource = async () => {
    const resourceId = Object.keys(this.props.multipleFilter[this.props.filteredKeyActive])[0]
    if (this.props.filteredKeyActive === filterKeys.BUSINESS) {
      await this.props.fetchProducersByBusinessId(resourceId, true)
      setTimeout(() => this.props.setBusinessPoints(this.props.producersByBusinessId.values), 1)
    }
    if (this.props.filteredKeyActive === filterKeys.PROVINCE) {
      await this.props.fetchProducersByProvinceId(resourceId, true)
      setTimeout(() => {
        this.props.setProvincePoints(this.props.producersByProvinceId.values)
      }, 1)
    }
    if (this.props.filteredKeyActive === filterKeys.ZONE) {
      await this.props.fetchProducersByZoneId(resourceId, true)
      setTimeout(() => this.props.setZonePoints(this.props.producersByZoneId.values), 1)
    }
  }

  openConfirmationPopUp = value => this.setState({ confirmationPopUp: value })

  closeConfirmationPopUp = () => this.setState({ confirmationPopUp: false })

  handleConfirmationAccept = async () => {
    const { coord, point } = this.state.confirmationPopUp
    await this.props.setMapStatus({
      showingInfoWindow: false,
      selectedPlace: {}
    })
    this.modifyCoordinates(coord, point)
    this.closeConfirmationPopUp()
  }

  modifyCoordinates = async ({ latLng }, point) => {
    const lat = latLng.lat()
    const lng = latLng.lng()

    const centerPos = { lat, lng }

    this.props.setMapStatus({
      ...this.props.mapStatus,
      center: centerPos
    })

    await this.props.modifyProducersCoordinates(point.IdUnidadProductora, lat, lng)

    if (this.state.selectedShape ? true : false) {
      this.fetchPointsByArea(this.state.selectedShape)
    } else if (isMultipleFilterEnabled(this.props.multipleFilter)) {
      this.handleFetchPointsByResource()
    }
  }

  render() {
    return (
      <Container>
        <EditWrapper edit={this.props.editMode.enabled}>
          <Map
            google={this.props.google}
            zoom={this.props.mapStatus.mapZoom}
            style={{
              width: this.props.editMode.enabled ? '99.7%' : '100%',
              height: this.props.editMode.enabled ? '99.5%' : '100%'
            }}
            onClick={this.onMapClick}
            center={{
              lat: this.props.mapStatus.center.lat,
              lng: this.props.mapStatus.center.lng
            }}
            onReady={this.initMap}
            ref={map => (this._map = map)}
          >
            <Marker
              position={
                this.props.mapStatus.selectedLocation &&
                this.props.mapStatus.selectedLocation.coordinates
              }
              onClick={(props, marker) => this.onMarkerClick(props, marker)}
              name={
                this.props.mapStatus.selectedLocation && this.props.mapStatus.selectedLocation.name
              }
            />
            {this.getPoints().map(point => {
              const formattedPoint = this.reestructureProducersData(point)
              return (
                <CustomMarker
                  key={formattedPoint.idUnidadProductora}
                  onDragend={(t, map, coord) => this.openConfirmationPopUp({ coord, point })}
                  onClick={(props, marker) => this.onMarkerClick(props, marker, formattedPoint)}
                  google={this.props.google}
                  point={formattedPoint}
                  draggable={
                    (this.props.editMode.enabled && formattedPoint.isTambo && userCanEdit()) ||
                    (this.props.editMode.enabled &&
                      !formattedPoint.isTambo &&
                      userCanEditIndustry())
                  }
                />
              )
            })}
            {this.props.mapStatus.selectedPlace && (
              <InfoWindow
                marker={this.props.mapStatus.activeMarker}
                visible={this.props.mapStatus.showingInfoWindow}
                onClose={this.onClose}
              >
                <CustomInfoWindow
                  accordionEvent={this.accordionEvent}
                  accordionEventValue={this.state.accordionEventValue}
                  selectedPlace={this.props.mapStatus.selectedPlace}
                  onHandleEditView={() => this.onHandleEditView()}
                  onHandleExpand={id => this.onHandleExpand(id)}
                  producerData={this.props.producersByProducerId}
                  expandStatus={this.state.isExpanded}
                  isFetchingObs={this.props.observations.isFetching}
                  observations={this.props.observations.values}
                  errorObs={this.props.observations.error}
                  businessAdditional={this.props.businessAdditional.values}
                  isFetchingBusAdd={this.props.businessAdditional.isFetching}
                  errorBusAdd={this.props.businessAdditional.error}
                />
              </InfoWindow>
            )}
          </Map>
        </EditWrapper>
        {this.state.confirmationPopUp && (
          <PopUp
            title={`${capitalizeFirstLetter(`${i18n('POPUP').RELOCATE}`)} "${
              this.state.confirmationPopUp.point.Nombre
            }"?`}
            onSuccess={this.handleConfirmationAccept}
            onCancel={this.closeConfirmationPopUp}
            onClose={this.closeConfirmationPopUp}
          />
        )}
      </Container>
    )
  }
}

const LoadingContainer = () => (
  <LoaderWrapper>
    <LoaderIconWrapper>
      <SyncLoader sizeUnit={'px'} size={15} color={theme.colors.mastelloneGreen} />
      <SyncLoader sizeUnit={'px'} size={15} color={theme.colors.mastelloneGreen} />
    </LoaderIconWrapper>
  </LoaderWrapper>
)

const mapStateToProps = ({
  editView,
  mapStatus,
  selectedProvince,
  selectedProvinceToDelete,
  producersByProducerId,
  multipleFilter,
  points: { filteredKeyActive },
  filter,
  producersByBusinessId,
  producersByProvinceId,
  producersByZoneId,
  observations,
  latestFilterApplied,
  editMode,
  businessAdditional
}) => ({
  editViewStatus: editView,
  mapStatus,
  selectedProvince,
  selectedProvinceToDelete,
  multipleFilter,
  filteredKeyActive,
  filter,
  producersByProducerId,
  producersByBusinessId,
  producersByProvinceId,
  producersByZoneId,
  observations,
  latestFilterApplied,
  editMode,
  businessAdditional
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setEditViewStatus,
      setMapStatus,
      resetSelectedProvinces,
      resetSelectdProvinceToDelete,
      fetchProducersByProducerId,
      resetProducersByProducerId,
      setActiveFiltersFromMap,
      fetchProducersByArea,
      deleteSelectedProvince,
      setSelectedProvinceToDelete,
      deleteProducersByProvinceId,
      fetchProducersByProvinceId,
      fetchProducersByBusinessId,
      modifyProducersCoordinates,
      fetchProducersByZoneId,
      resetProducersInfo,
      resetFilters,
      resetPoints,
      setBusinessPoints,
      setProvincePoints,
      setZonePoints,
      setDryingPoints,
      reset,
      resetAreaPoints,
      fetchObservations,
      setActiveRadius,
      removeActiveRadius,
      resetSelectdRecordedQueriesById,
      resetProducersByIds,
      fetchBusinessAdditional
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  GoogleApiWrapper({
    apiKey: googleMapApiKey,
    libraries: ['drawing'],
    LoadingContainer: LoadingContainer
  })(MapContainer)
)
