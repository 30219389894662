import styled from 'styled-components'

export const StyledDivWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyledDivWrapperInfo = styled.div`
  margin-bottom: 10px;
`
export const StyledDivAccordion = styled.div`
  max-width: 65%;
  min-width: 65%;
`

export const StyledDivWrapperData = styled.div`
  min-width: ${({ isExpanded }) => (isExpanded ? '500px' : '200px')};
`
export const StyledDivWrapperExpand = styled.div`
  margin: auto auto;
`
export const StyledTitle = styled.text`
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-block-start: 0.7em;
`
export const StyledSubTitle = styled.text`
  font-size: 13px;
  font-weight: 300;
  display: block;
  margin-block-end: 1em;
`

export const StyledLore = styled.text`
  font-size: 12px;
  font-weight: ${({ bold }) => (bold ? 500 : 300)};
  display: block;
  width: fit-content;
`

export const StyledBoldLore = styled.text`
  font-weight: 500;
`

export const StyledButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: #19975d;
  padding: 10px;
  border-radius: 10px;
  color: white;
  margin-block-start: 1em;
`

export const StyledButtonExpand = styled.button`
  border: none;
  padding: unset;
  background: white;
  cursor: pointer;
`

export const StyledDivWrapperObs = styled.div`
  display: ${({ expandStatus }) => (expandStatus ? 'flex' : 'none')};
  width: 100%;
  margin: ${({ isFetchingObs }) => (isFetchingObs ? 'auto auto' : 'unset')};
  text-align: ${({ isFetchingObs }) => (isFetchingObs ? 'center' : 'unset')};

  padding-left: 20px;
  border-left: ${({ isFetchingObs }) => (!isFetchingObs ? 'solid 1px #8080804d' : 'unset')};
  flex-direction: column;
`

export const DividerTitleStyles = {
  margin: '5px 0px 5px 0px'
}

export const DividerObsStyles = display => ({
  margin: '5px 0px 5px 0px',
  display: display
})

export const StyledDivWrapperItems = styled.div`
  max-height: 200px;
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar !important;
`
export const StyledImg = styled.img`
  height: 75px;
`

export const StyleBusinessDiv = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ListWRapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > li {
    padding: 0;
  }
`

export const Title = styled.label`
  font-size: 20px;
  padding: 10px 0px 10px 23px;
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
`
export const DividerLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: black;
  margin: 5px 0;
`
