import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchProducersByLevel } from 'store/actions/producersByLevel'
import SimpleCheckList from 'components/SimpleCheckList'
import ExpandableElement from 'components/ExpandableElement'
import styled from 'styled-components'
import { capitalizeFirstLetter } from 'utils/strings'
import i18n from 'services/i18n'
import { setSelectedBusiness, deleteSelectedBusiness } from 'store/actions/multipleFilter'
import { fetchProducersByBusinessId } from 'store/actions/producersByBusinessId'
import { setBusinessPoints } from 'store/actions/points'
import filterKeys from 'constants/filterKeys'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ColorCircle = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`

export const ListWRapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > li {
    padding: 0;
  }
`

export const Title = styled.label`
  font-size: 20px;
  padding: 10px 0px 10px 23px;
`

class LevelSection extends React.Component {
  state = {
    expanded: 0,
    selected: []
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.producersByArea.values && this.props.producersByArea.values) ||
      (!prevProps.recordedQueries.valuesById && this.props.recordedQueries.valuesById)
    ) {
      this.setState({ selected: [], expanded: 0 })
    }
  }

  handleSelectorChange = id => {
    let selected = this.state.selected
    const pos = selected.indexOf(id)
    if (pos >= 0) {
      selected.splice(pos, 1)
      this.handleDeletePoints(id)
    } else {
      selected.push(id)
      this.handleShowPoints(id)
      this.setState({ selected })
    }
  }

  handleShowPoints = async id => {
    this.props.resetPartial()
    this.props.producersByLevel.values[`lvl${id}`].map(async business => {
      await this.props.setSelectedBusiness(business)
    })
    if (this.handleFetchContent(filterKeys.BUSINESS)) {
      this.fetchContent(id)
    }
  }

  fetchContent = async id => {
    await this.props.fetchProducersByBusinessId(this.props.producersByLevel.values[`lvl${id}`])
    this.props.setBusinessPoints(this.props.producersByBusinessId.values)
  }

  handleDeletePoints = async id => {
    this.props.producersByLevel.values[`lvl${id}`].map(
      async business => await this.props.deleteSelectedBusiness(business)
    )
    setTimeout(() => {
      if (this.props.filteredKeyActive === filterKeys.BUSINESS) {
        if (this.getResourceLength(this.props.multipleFilter.business) === 0) {
          this.props.resetAll()
        } else {
          this.fetchContent(id)
        }
      }
    }, 10)
  }

  getResourceLength = resource => Object.keys(resource).length

  handleFetchContent = key => !this.props.filteredKeyActive || this.props.filteredKeyActive === key

  renderExpandableElement = id => (
    <ExpandableElement
      title={`${capitalizeFirstLetter(`${i18n('LEVEL_SELECTION').LEVEL}`)} ${id}`}
      withCheck
      isLoading={this.props.producersByLevel.isFetching}
      id={id}
      handleSelectorChange={id => this.handleSelectorChange(id)}
      expanded={this.state.expanded === id}
      onChange={() => this.handleChange(id)}
      checked={this.state.selected.indexOf(id) > -1 ? true : false}
      disabled={this.props.disabled}
    >
      <ListWRapper>{this.checkContent(id)}</ListWRapper>
    </ExpandableElement>
  )

  checkContent = id =>
    this.props.data[`lvl${id}`].length ? (
      this.renderContent(id)
    ) : (
      <lable>{capitalizeFirstLetter(`${i18n('LEVEL_SELECTION').ERROR}`)}</lable>
    )

  renderContent = id => (
    <SimpleCheckList
      values={this.props.data[`lvl${id}`]}
      isLoading={false}
      onItemSelected={this.props.onItemSelected}
      selected={this.props.selected}
      disabled={this.props.disabled}
      business
    />
  )

  handleChange = async level => {
    if (this.state.expanded !== level && !this.props.producersByLevel.isFetching) {
      this.setState({ expanded: level })
    } else {
      this.setState({ expanded: 0 })
    }
  }

  render() {
    return (
      <Container>
        {this.props.title && <Title>{this.props.title}</Title>}
        {this.renderExpandableElement(1)}
        {this.renderExpandableElement(2)}
        {this.renderExpandableElement(3)}
      </Container>
    )
  }
}

const mapStateToProps = ({
  producersByLevel,
  producersByBusinessId,
  points: { filteredKeyActive },
  multipleFilter,
  producersByArea,
  recordedQueries,
  basePoints
}) => ({
  producersByLevel,
  producersByBusinessId,
  filteredKeyActive,
  multipleFilter,
  producersByArea,
  recordedQueries,
  basePoints
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProducersByLevel,
      setSelectedBusiness,
      deleteSelectedBusiness,
      fetchProducersByBusinessId,
      setBusinessPoints
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LevelSection)
