import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import Hidden from '@material-ui/core/Hidden'
import MapComponent from 'components/Map'
import compose from 'recompose/compose'
import SideBar from 'components/SideBar'
import Page from 'components/Page'
import ItemEdit from 'components/ItemEdit'
import LoaderDialog from 'components/loaders/loaderDialog'
import { mapPoints } from 'store/selectors/filterPoints'
import { mapProducersInfo } from 'store/selectors/filterPoints'
import { AlertToast, ErrorToast } from 'components/Toast'
import { toast } from 'react-toastify'
import { getUrlIds } from 'utils/url'
import { getCacheIds } from 'utils/session'
import { fetchProducersByIds } from 'store/actions/producersByIds'
import ProducersTableHidden from 'components/ProducersTableHidden'
import { editModeEnabled, editModeDisabled } from 'store/actions/editMode'
import { openDrawer, closeDrawer } from 'store/actions/ui'
import OptionsMenu from 'components/OptionsMenu'
import ButtonRounded from 'components/ButtonRounded'
import Icon, { IconNames } from 'components/Icons'
import MapHeader from 'components/MapHeader'
import theme from 'config/theme'
import i18n from 'services/i18n'
import { setMapStatus } from 'store/actions/mapStatus'
import { capitalizeFirstLetter } from 'utils/strings'
import { resetFilters } from 'store/actions/filter'
import { resetLatestFilterApplied } from 'store/actions/latestFilterApplied'
import { basePosition } from 'config/map'
import { reset, resetSelectedRecordedQueries } from 'store/actions/multipleFilter'
import { userCanEdit, removeLocalStatus, userCanEditIndustry } from 'utils/session'
import { resetPoints } from 'store/actions/points'
import { resetAreaPoints } from 'store/actions/producersByArea'
import { resetProducersByBusinessId } from 'store/actions/producersByBusinessId'
import { resetProducersByIds } from 'store/actions/producersByIds'
import { resetProducersByLevel } from 'store/actions/producersByLevel'
import { resetProducersByProducerId } from 'store/actions/producersByProducerId'
import { resetProducersByProvinceId } from 'store/actions/producersByProvinceId'
import { resetProducersByZoneId } from 'store/actions/producersByZoneId'
import { resetProducersInfo } from 'store/actions/producersInfo'
import {
  resetSelectdRecordedQueriesById,
  resetAllSelectdRecordedQueries
} from 'store/actions/recordedQueries'
import { resetSelectedProvinces } from 'store/actions/selectedProvince'
import { removeActiveRadius } from 'store/actions/ui'
import {
  StyledDivRoot,
  MapWrapper,
  StyledMainContent,
  PageWrapper,
  DataWrapper,
  EditModeAlert,
  StyledDrawer,
  StyledNav,
  MenuStateContainer,
  ActiveRadiusAlert
} from './styled'

const drawerWidth = 240

const styles = () => ({
  drawerPaper: {
    width: drawerWidth
  },
  avatarIcon: {
    height: '30px',
    width: '30px',
    color: 'white',
    marginTop: 'auto',
    marginBottom: 'auto'
  }
})

class Map extends Component {
  state = {
    mobileOpen: false,
    isEditOpen: false
  }

  componentDidMount = () => {
    const ids = getUrlIds() || getCacheIds()
    if (ids) {
      this.props.fetchProducersByIds(ids)
    }
  }

  componentDidUpdate = prevProps => {
    if (
      !prevProps.producersByArea.values &&
      this.props.producersByArea.values &&
      this.props.producersByArea.values.length === 0
    ) {
      toast(
        <AlertToast
          boldContent={capitalizeFirstLetter(`${i18n('ALERT_POPUP').SORRY}`)}
          content={capitalizeFirstLetter(`${i18n('ALERT_POPUP').NO_POINTS}`)}
        />
      )
    }
    if (!prevProps.producersByBusinessId.error && this.props.producersByBusinessId.error) {
      toast(
        <ErrorToast
          boldContent={capitalizeFirstLetter(`${i18n('ALERT_POPUP').SORRY}`)}
          content={capitalizeFirstLetter(`${i18n('ALERT_POPUP').NO_BUSINESS_POINTS}`)}
        />
      )
    }
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }))
  }

  panelExpandableExist = () =>
    this.props.expandablePanelState ? this.props.expandablePanelState : ''

  clearReducers = () => {
    this.props.editModeDisabled()
    this.props.resetFilters()
    this.props.resetLatestFilterApplied()
    this.props.setMapStatus({
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      mapZoom: 5,
      selectedLocation: null,
      center: basePosition
    })
    this.props.reset()
    this.props.resetSelectedRecordedQueries()
    this.props.resetPoints()
    this.props.resetAreaPoints()
    this.props.resetProducersByBusinessId()
    this.props.resetProducersByIds()
    this.props.resetProducersByProducerId()
    this.props.resetProducersByLevel()
    this.props.resetProducersByProvinceId()
    this.props.resetProducersByZoneId()
    this.props.resetProducersInfo()
    this.props.resetSelectdRecordedQueriesById()
    this.props.resetAllSelectdRecordedQueries()
    this.props.resetSelectedProvinces()
    this.props.removeActiveRadius()
  }

  logout = history => {
    removeLocalStatus()
    this.clearReducers()
    history.push(`/login`)
  }

  getPoints = () =>
    this.props.producersByArea.values ||
    this.props.producersByIds.values ||
    this.props.pointsMapped ||
    []

  toggleEditMode = () => {
    if (this.props.editMode.enabled) {
      this.props.editModeDisabled()
    } else {
      this.props.editModeEnabled()
      this.props.setMapStatus({
        showingInfoWindow: false,
        selectedPlace: {}
      })
    }
  }

  renderPageContent = () => {
    const { classes, history } = this.props
    return (
      <StyledDivRoot>
        <CssBaseline />
        <MapHeader
          history={history}
          classes={classes}
          onLogout={() => this.logout(history)}
          onDrawerToggle={this.handleDrawerToggle}
        />
        <StyledNav drawerWidth={this.props.ui.isDrawerOpen ? drawerWidth : 0}>
          <Hidden smUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
            >
              <SideBar pointsMapped={this.getPoints()} />
            </Drawer>
          </Hidden>
          <StyledDrawer
            visible={this.props.ui.isDrawerOpen}
            drawerWidth={drawerWidth}
            variant="permanent"
          >
            <SideBar mobileHidden pointsMapped={this.getPoints()} />
          </StyledDrawer>
        </StyledNav>
        <DataWrapper>
          <StyledMainContent isPanelExpanded={this.panelExpandableExist()}>
            <MapWrapper isPanelExpanded={this.panelExpandableExist()}>
              <MapComponent rawPoints={this.props.rawPoints} points={this.getPoints()} />
            </MapWrapper>
          </StyledMainContent>
          <MenuStateContainer>
            <ButtonRounded
              backgroundColor={theme.colors.green}
              onClick={() =>
                this.props.ui.isDrawerOpen ? this.props.closeDrawer() : this.props.openDrawer()
              }
            >
              <Icon
                name={this.props.ui.isDrawerOpen ? IconNames.ArrowLeft : IconNames.ArrowRight}
                color={theme.colors.white}
                size={30}
              />
            </ButtonRounded>
          </MenuStateContainer>
          <OptionsMenu>
            {(userCanEdit() || userCanEditIndustry()) && (
              <ButtonRounded
                onClick={this.toggleEditMode}
                backgroundColor={
                  this.props.editMode.enabled ? theme.colors.red : theme.colors.green
                }
              >
                <Icon name={IconNames.EditEnabled} color={theme.colors.white} size={30} />
              </ButtonRounded>
            )}
            {this.props.producersInfo && <ProducersTableHidden info={this.props.producersInfo} />}
          </OptionsMenu>
          {this.props.editMode.enabled && (
            <EditModeAlert>
              <label>{i18n('EDIT_MODE_ENABLED')}</label>
            </EditModeAlert>
          )}
          {this.props.producersByArea.values && (
            <ActiveRadiusAlert>{`${this.props.ui.activeRadius} Kms.`}</ActiveRadiusAlert>
          )}
        </DataWrapper>
      </StyledDivRoot>
    )
  }

  isFetching = () =>
    this.props.producersByArea.isFetching ||
    this.props.producersByBusinessId.isFetching ||
    this.props.producersByProvinceId.isFetching ||
    this.props.producersByZoneId.isFetching ||
    this.props.recordedQueries.isFetchingById ||
    this.props.producersByIds.isFetching ||
    this.props.modifyProducersCoordinatesStatus.isFetching
      ? true
      : false

  render() {
    return (
      <Page>
        <PageWrapper>
          {this.renderPageContent()}
          {this.props.editViewStatus.open && <ItemEdit />}
          {this.isFetching() ? <LoaderDialog isSideBarOpen={this.props.ui.isDrawerOpen} /> : ''}
        </PageWrapper>
      </Page>
    )
  }
}

Map.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProducersByIds,
      editModeEnabled,
      editModeDisabled,
      openDrawer,
      closeDrawer,
      setMapStatus,
      resetFilters,
      resetLatestFilterApplied,
      reset,
      resetSelectedRecordedQueries,
      resetPoints,
      resetAreaPoints,
      resetProducersByBusinessId,
      resetProducersByIds,
      resetProducersByProducerId,
      resetProducersByLevel,
      resetProducersByProvinceId,
      resetProducersByZoneId,
      resetProducersInfo,
      resetSelectdRecordedQueriesById,
      resetAllSelectdRecordedQueries,
      resetSelectedProvinces,
      removeActiveRadius
    },
    dispatch
  )

const mapStateToProps = ({
  editView,
  producersByBusinessId,
  producersByProvinceId,
  producersByZoneId,
  producersByArea,
  producersInfo,
  points,
  multipleFilter,
  modifyProducersCoordinatesStatus,
  producersByIds,
  editMode,
  ui,
  recordedQueries,
  mapStatus
}) => {
  const pointsMapped = mapPoints({ points, multipleFilter })
  return {
    editViewStatus: editView,
    producersByBusinessId,
    producersByProvinceId,
    producersByZoneId,
    producersByArea,
    rawPoints: points.basePoints,
    pointsMapped,
    multipleFilter,
    producersInfo: mapProducersInfo({
      producersInfo,
      pointsMapped,
      multipleFilter
    }),
    modifyProducersCoordinatesStatus,
    producersByIds,
    editMode,
    ui,
    recordedQueries,
    mapStatus
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withStyles(styles, { withTheme: true }))(Map))
