import { createActions } from 'redux-actions'
import getApi from 'services/api'
import { removeCachedIds } from 'utils/session'
import { setProducersInfo } from 'store/actions/producersInfo'
import API_ROUTES from 'config/apiRoutes'

const {
  fetchProducersByIdStart,
  fetchProducersByIdSuccess,
  fetchProducersByIdError,
  producersByIdReset
} = createActions({
  FETCH_PRODUCERS_BY_ID_START: () => {},
  FETCH_PRODUCERS_BY_ID_SUCCESS: data => ({ data }),
  FETCH_PRODUCERS_BY_ID_ERROR: error => ({ error }),
  PRODUCERS_BY_ID_RESET: () => {}
})

const fetchProducersByIds = user => {
  return async dispatch => {
    dispatch(fetchProducersByIdStart())
    const api = await getApi()
    try {
      const response = await api.get(`${API_ROUTES.PRODUCERS_BY_IDS}/${user}`)
      dispatch(fetchProducersByIdSuccess(response.data.productoras))
      dispatch(setProducersInfo(response.data.totalProductoras))
      removeCachedIds()
    } catch (error) {
      dispatch(fetchProducersByIdError(error))
      removeCachedIds()
    }
  }
}

const resetProducersByIds = () => dispatch => dispatch(producersByIdReset())

export {
  fetchProducersByIds,
  fetchProducersByIdStart,
  fetchProducersByIdSuccess,
  fetchProducersByIdError,
  resetProducersByIds,
  producersByIdReset
}
