import { createActions } from 'redux-actions'
import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const {
  fetchProducersByProducerIdStart,
  fetchProducersByProducerIdSuccess,
  fetchProducersByProducerIdError,
  resetProducersByProducer
} = createActions({
  FETCH_PRODUCERS_BY_PRODUCER_ID_START: () => {},
  FETCH_PRODUCERS_BY_PRODUCER_ID_SUCCESS: data => ({ data }),
  FETCH_PRODUCERS_BY_PRODUCER_ID_ERROR: error => ({ error }),
  RESET_PRODUCERS_BY_PRODUCER: () => {}
})

const fetchProducersByProducerId = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchProducersByProducerIdStart())
    try {
      const response = await api.get(`${API_ROUTES.PRODUCERS_BY_PRODUCER_ID}/${id}`)
      if (!response) throw Error()
      dispatch(fetchProducersByProducerIdSuccess({ values: response.data }))
    } catch (error) {
      //TODO catch the error provided from the api
      dispatch(fetchProducersByProducerIdError(error))
    }
  }
}

const resetProducersByProducerId = () => dispatch => dispatch(resetProducersByProducer())

export {
  fetchProducersByProducerId,
  fetchProducersByProducerIdStart,
  fetchProducersByProducerIdSuccess,
  fetchProducersByProducerIdError,
  resetProducersByProducerId,
  resetProducersByProducer
}
