import { handleActions } from 'redux-actions'
import {
  fetchDryingStart,
  fetchDryingSuccess,
  fetchDryingError,
  deleteDryingFilter
} from 'store/actions/drying'

const defaultState = {
  values: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchDryingStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchDryingSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values: values,
      isFetching: false,
      error: null
    }),
    [fetchDryingError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [deleteDryingFilter]: (state, { payload: { producers } }) => ({
      ...state,
      values: producers
    })
  },
  defaultState
)

export default reducer
