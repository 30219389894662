import React from 'react'
import { capitalizeFirstLetter } from 'utils/strings'
import i18n from 'services/i18n'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import ExitIcon from '@material-ui/icons/ExitToApp'
import EmptyBottle from 'assets/icons/botella-vacia.svg'
import HalfBottle from 'assets/icons/botella-media.svg'
import FullBottle from 'assets/icons/botella-llena.svg'
import Logo from 'assets/imgs/logo.png'
import {
  StyledAppBar,
  StyledTitle,
  StyledIconButton,
  StyledExitButton,
  Container,
  ReferenceImage,
  InfoContainer,
  Separator,
  LogoWrapper,
  StyledImg
} from './styled'

const drawerWidth = 240

const MapHeader = ({ classes, onLogout, onDrawerToggle }) => (
  <StyledAppBar drawerWidth={drawerWidth}>
    <Toolbar style={{ justifyContent: 'space-between' }}>
      <StyledIconButton color="inherit" aria-label="Open drawer" onClick={onDrawerToggle}>
        <MenuIcon />
      </StyledIconButton>
      <LogoWrapper>
        <StyledImg src={Logo} />
      </LogoWrapper>
      <StyledTitle drawerWidth={150}>{capitalizeFirstLetter(`${i18n('APP_NAME')}`)}</StyledTitle>
      <InfoContainer>
        <Container>
          <ReferenceImage src={EmptyBottle} />
          <label>{capitalizeFirstLetter(`${i18n('TAMBO_HINT').UNTIL}`)}</label>
        </Container>
        <Separator />
        <Container>
          <ReferenceImage src={HalfBottle} />
          <label>{capitalizeFirstLetter(`${i18n('TAMBO_HINT').BETWEEN}`)}</label>
        </Container>
        <Separator />
        <Container>
          <ReferenceImage src={FullBottle} />
          <label>{capitalizeFirstLetter(`${i18n('TAMBO_HINT').HIGHER}`)}</label>
        </Container>
      </InfoContainer>
      <Container>
        <StyledExitButton onClick={onLogout}>
          <ExitIcon className={classes.avatarIcon} />
        </StyledExitButton>
      </Container>
    </Toolbar>
  </StyledAppBar>
)

export default MapHeader
