import filterKeys from 'constants/filterKeys'

export const getFirstObjectValue = object => Object.values(object)[0]

export const isMultipleFilterEnabled = multipleFilter =>
  multipleFilter &&
  Object.values(multipleFilter).reduce((accum, actual) => accum + Object.values(actual).length, 0)
    ? true
    : false

export const multiFilterKeyAmount = multipleFilter => {
  const businessFilter = Object.keys(multipleFilter[filterKeys.BUSINESS]).length >= 1
  const provinceFilter = Object.keys(multipleFilter[filterKeys.PROVINCE]).length >= 1
  const zoneFilters = Object.keys(multipleFilter[filterKeys.ZONE]).length >= 1
  return zoneFilters + provinceFilter + businessFilter
}
