import { handleActions } from 'redux-actions'
import { setFilterApplied, resetFilterApplied } from 'store/actions/latestFilterApplied'

const defaultState = null

const reducer = handleActions(
  {
    [setFilterApplied]: (state, { payload: { key } }) => key,
    [resetFilterApplied]: () => defaultState
  },
  defaultState
)

export default reducer
