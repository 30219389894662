import { handleActions } from 'redux-actions'
import { editViewStatus } from 'store/actions/editView'

const defaultState = { open: false }

const reducer = handleActions(
  {
    [editViewStatus]: (
      state,
      {
        payload: {
          data: { status }
        }
      }
    ) => {
      return status
    }
  },
  defaultState
)

export default reducer
