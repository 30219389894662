import { createActions } from 'redux-actions'

const { setInfo, resetInfo } = createActions({
  SET_INFO: data => ({ data }),
  RESET_INFO: () => {}
})

const setProducersInfo = data => dispatch => dispatch(setInfo(data))

const resetProducersInfo = () => dispath => dispath(resetInfo())

export { setInfo, setProducersInfo, resetInfo, resetProducersInfo }
