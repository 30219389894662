import styled from 'styled-components'
import mediaQueries from 'config/media-queries'

export const StyledDivRoot = styled.div`
  display: flex;
  height: 100%;
`

export const GreetingsWrapper = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.mastelloneDarkGreen};
  display: flex;
  justify-content: space-between;
`
export const Greeting = styled.div`
  display: flex;
`

export const GreetingsTypography = styled.text`
  color: ${({ theme }) => theme.colors.white};
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
`

export const StyledLogoWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.mastelloneGreen};
  height: 64px;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
`

export const StyledImg = styled.img`
  width: 60%;
`
export const MapWrapper = styled.div`
  width: 100%;
  height: calc(100% - 64px);
  margin-top: 64px;
  position: absolute;

  & > div {
    width: 100%;
    height: 100%;
  }

  ${mediaQueries.mobile`
		width: 100%;
		height: calc(100% - 56px);
		margin-top: 56px;
`};
`
export const StyledMainContent = styled.div`
  height: 100%;
  flex-grow: 1;
  justify-content: center;
  display: block;
  position: relative;
`

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  align-items: normal;

  &:before {
    animation: portal-profile-before 0.6s ease-out forwards 1;
    animation-delay: 0.2s;
  }
  &:after {
    animation: portal-profile-after 0.4s ease-out forwards 1;
    animation-delay: 0.4s;
  }
`

export const DataWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`
export const EditModeAlert = styled.div`
  width: 200px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  border-radius: 3px;
  color: white;
  font-weight: 800;
  position: absolute;
  right: 40%;
  bottom: 2px;
`

export const ActiveRadiusAlert = styled.div`
  width: 200px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.mastelloneGreen};
  border-radius: 3px;
  color: white;
  font-weight: 800;
  position: absolute;
  right: 40%;
  top: 70px;
`

export const StyledNav = styled.nav`
  box-sizing: inherit;
  display: block;
  width: ${({ drawerWidth }) => `${drawerWidth}px`};
  flex-shrink: 0;
  ${mediaQueries.mobile`
  margin-left: 0px;
  width: 0px;
`};
`

export const StyledDrawer = styled.div`
  box-sizing: inherit;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: none;
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  z-index: 1200;
  outline: none;
  position: fixed;
  flex-direction: column;
  left: 0;
  right: auto;
  width: ${({ drawerWidth }) => `${drawerWidth}px`};
  ${mediaQueries.mobile`
    width:  ${({ open, drawerWidth }) => (open ? `${drawerWidth}px` : '0px')};
  `};
`

export const MenuStateContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;

  ${mediaQueries.mobile`
    display: none;
  `};
`
