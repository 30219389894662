import { handleActions } from 'redux-actions'
import {
  fetchBusinessAdditionalStart,
  fetchBusinessAdditionalSuccess,
  fetchBusinessAdditionalError
} from 'store/actions/businessAdditional'

const defaultState = {
  values: [],
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchBusinessAdditionalStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchBusinessAdditionalSuccess]: (
      state,
      {
        payload: {
          data: { values }
        }
      }
    ) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchBusinessAdditionalError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error
    })
  },
  defaultState
)

export default reducer
