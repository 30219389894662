import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { capitalizeFirstLetter } from 'utils/strings'

dayjs.locale('es')

export const getDate = date => {
  const actualDate = dayjs(date)
  return `${actualDate.format('DD')} de ${capitalizeFirstLetter(
    actualDate.format('MMMM')
  )}, ${actualDate.format('YYYY')}`
}

export const getShortDate = date => {
  const actualDate = dayjs(date)
  return `${actualDate.format('DD')}/${actualDate.format('MM')}/${actualDate.format('YY')}`
}
