import { createActions } from 'redux-actions'
import getApi from 'services/api'
import API_ROUTES from 'config/apiRoutes'

const {
  fetchProducersByLevelStart,
  fetchProducersByLevelSuccess,
  fetchProducersByLevelError,
  producersByLevelReset,
  setBaseProducersByLevel,
  producersByLevelRestart
} = createActions({
  FETCH_PRODUCERS_BY_LEVEL_START: () => {},
  FETCH_PRODUCERS_BY_LEVEL_SUCCESS: data => ({ data }),
  FETCH_PRODUCERS_BY_LEVEL_ERROR: error => ({ error }),
  PRODUCERS_BY_LEVEL_RESET: () => {},
  SET_BASE_PRODUCERS_BY_LEVEL: data => ({ data }),
  PRODUCERS_BY_LEVEL_RESTART: () => {}
})

const fetchProducersByLevel = level => {
  return async dispatch => {
    dispatch(fetchProducersByLevelStart())
    const api = await getApi()
    try {
      const response = await api.get(`${API_ROUTES.PRODUCERS_BY_LEVEL}/${level}`)
      if (!response) throw Error()
      dispatch(fetchProducersByLevelSuccess({ response, level }))
      dispatch(setBaseProducersByLevel({ response, level }))
    } catch (error) {
      dispatch(fetchProducersByLevelError(error))
    }
  }
}

const restartProducersByLevel = () => dispatch => dispatch(producersByLevelRestart())

const resetProducersByLevel = () => dispatch => dispatch(producersByLevelReset())

export {
  fetchProducersByLevel,
  fetchProducersByLevelStart,
  fetchProducersByLevelSuccess,
  fetchProducersByLevelError,
  resetProducersByLevel,
  producersByLevelReset,
  setBaseProducersByLevel,
  producersByLevelRestart,
  restartProducersByLevel
}
