import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.transparentBlack};
  z-index: 9999;
`
export const SpanDate = styled.div`
  margin-bottom: 50px;
  margin-top: 20px;
`

export const MainDiv = styled.div`
  width: 80%;
  height: 80%;
  left: 50%;
  top: 50%;
  transform: translate(13%, 13%);
  background-color: white;
  text-align: center;
`
export const BodyDiv = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: scroll;
`

export const CloseSapn = styled.span`
  position: absolute;
  right: 33px;
  top: 7px;
  cursor: pointer;
  font-weight: bold;
  font-family: cursive;
`
export const ImgDiv = styled.div`
width:100%
margin-top: 30px;
`
export const Img = styled.img`
  width: 70%;
`
export const StyledSubTitle = styled.text`
  font-size: 13px;
  font-weight: 300;
  display: block;
  margin-block-end: 1em;
`

export const StyledBoldLore = styled.text`
  font-weight: 500;
`
export const StyledLore = styled.text`
  font-size: 12px;
  font-weight: ${({ bold }) => (bold ? 500 : 300)};
  display: block;
  width: fit-content;
`

export const DividerObsStyles = display => ({
  margin: '5px 0px 5px 0px',
  display: display
})

export const StyledDivWrapperItems = styled.div`
  max-height: 200px;
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar !important;
`
