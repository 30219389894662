export const capitalizeFirstLetter = string =>
  string ? `${string.charAt(0).toUpperCase()}${string.slice(1)}` : ''

export const lowerFirsLetter = string =>
  string ? `${string.charAt(0).toLowerCase()}${string.slice(1)}` : ''

export const allToCaps = string => (string ? `${string.toUpperCase()}` : '')

export const allToLower = string => (string ? `${string.toLowerCase()}` : '')

export const getShorterString = (string, maxCharAmount) =>
  `${string.substring(0, maxCharAmount)}...`

export const renderItemContent = (content, color) => {
  const maxCharAmount = color ? 12 : 14
  const contentData =
    content.length > maxCharAmount ? getShorterString(content, maxCharAmount) : content
  return capitalizeFirstLetter(allToLower(contentData))
}
